type Notification = {
  type: 'error' | 'success'
  title: string
  message: string
}

type State = Notification[]

type Action = {
  type: 'PUSH_NOTIFICATION'
  payload: Notification
} | {
  type: 'POP_NOTIFICATION'
  payload: number
}

export const pushNotification = (notification: Notification) => dispatch => {
  dispatch({
    type: 'PUSH_NOTIFICATION',
    payload: notification
  })
}

export const popNotification = (index: number) => dispatch => {
  dispatch({
    type: 'POP_NOTIFICATION',
    payload: index
  })
}

const initialState: State = []

export const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case 'PUSH_NOTIFICATION': {
      return [
        ...state,
        action.payload
      ]
    }

    case 'POP_NOTIFICATION': {
      const notifications = [...state]

      notifications.splice(action.payload, 1)

      return notifications
    }

    default: {
      return state
    }
  }
}

export const getNotifications = (state): State => {
  return state.notifications
}
