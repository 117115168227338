import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

import { getNotifications, popNotification } from 'actions/notifications'
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'

export const Notifications: React.FC = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(getNotifications)

  useEffect(() => {
    let timeoutId

    if (notifications.length > 0) {
      timeoutId = setTimeout(() => {
        dispatch(popNotification(0))
      }, 3000)
    }

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId)
      }
    }
  }, [dispatch, notifications])

  return (
    <>
      <div className='absolute md:w-1/3 lg:w-1/4 top-0 right-0 m-4' style={{ zIndex: 1 }}>
        {notifications?.length > 0 && notifications.map(({ message, title, type }, i) => (
          <Alert key={i} variant={type === 'error' ? 'destructive' : 'default'} className='mb-4'>
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>
              {message}
            </AlertDescription>
          </Alert>
        ))}
      </div>
    </>
  )
}
