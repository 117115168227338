import React from 'react'
import classNames from 'classnames'

export default class HalfRectangle extends React.Component {
  render () {
    const { color, reference } = this.props

    return (
      <svg className={classNames({ reference })}>
        <line x1='1' y1='10' x2='1' y2='20' stroke={color} strokeWidth='1' />
        <line x1='20' y1='20' x2='20' y2='10' stroke={color} strokeWidth='1' />
        <line x1='0' y1='20' x2='20' y2='20' stroke={color} strokeWidth='1' />
      </svg>
    )
  }
}
