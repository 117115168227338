import {
  CLEAN_FILES,
  DELETE_FILE_BEGIN,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_V1_SUCCESS,
  IMAGE_UPLOAD_BEGIN,
  IMAGE_UPLOAD_FAILURE,
  IMAGE_UPLOAD_SUCCESS,
  GET_FILE_BEGIN,
  GET_FILE_FAILURE,
  GET_FILE_SUCCESS,
  GET_FILES_BEGIN,
  GET_FILES_FAILURE,
  GET_FILES_SUCCESS,
  GET_IMAGE_BEGIN,
  GET_IMAGE_FAILURE,
  GET_IMAGE_SUCCESS,
  SELECT_IMAGE,
  UPLOAD_FAILURE,
  UPLOAD_SUCCESS
} from '../actions/files'

const initialState = {
  images: [],
  selectedImageId: null,
  uploading: false,
  loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_FILES:
      return {
        ...state,
        images: []
      }

    case IMAGE_UPLOAD_BEGIN:
      return {
        ...state,
        uploading: true,
        error: null
      }

    case IMAGE_UPLOAD_FAILURE:
    case UPLOAD_FAILURE:
      return {
        ...state,
        uploading: false,
        error: action.payload
      }

    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: false
      }

    case DELETE_FILE_BEGIN:
    case GET_FILE_BEGIN:
    case GET_FILES_BEGIN:
      return {
        ...state,
        loading: true
      }

    case DELETE_FILE_FAILURE:
    case GET_FILE_FAILURE:
    case GET_FILES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case DELETE_FILE_SUCCESS: {
      return {
        ...state,
        files: state.files.filter((file) => file.file !== action.fileId),
        loading: false
      }
    }

    case DELETE_FILE_V1_SUCCESS: {
      const imageToRemoveIndex = state.images.findIndex(image => image._id === action.fileId)

      state.images.splice(imageToRemoveIndex, 1)

      return {
        ...state,
        loading: false
      }
    }

    case GET_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.payload
      }

    case GET_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        files: action.payload
      }

    case GET_IMAGE_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      }

    case GET_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case GET_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        images: [
          ...state.images,
          {
            _id: action.payload._id,
            image: URL.createObjectURL(action.payload.image)
          }
        ]
      }

    case SELECT_IMAGE:
      return {
        ...state,
        selectedImageId: action.payload
      }

    case UPLOAD_SUCCESS:
      return {
        ...state,
        error: null,
        files: [...state.files, action.payload.data],
        uploading: false
      }

    default:
      return state
  }
}
