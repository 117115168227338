import BaseHttpService from './baseHttpService'

class PatientsService {
  constructor () {
    this.apiV1 = BaseHttpService.getApiV1Url()
  }

  createPatient (data) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify(data)
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/patients/`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  createTreatment (data, patientId) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify({ ...data, patient: patientId })
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/odonthogram/`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  deletePatient (patientId) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'put'
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/patients/${patientId}/delete`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  getPatient (patientId) {
    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/patients/${patientId}`, BaseHttpService.getOptions())
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse.data)
        })
        .catch(err => reject(err))
    })
  }

  updatePatient (patientId, data) {
    ['odonthograms', 'createdAt', 'updatedAt', '_id', '__v'].forEach(key => {
      delete data[key]
    })

    const options = {
      ...BaseHttpService.getOptions(),
      method: 'put',
      body: JSON.stringify(data)
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/patients/${patientId}`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }
}

export default new PatientsService()
