import React, { useState } from 'react'
import classnames from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { cleanState as cleanPatientsState } from 'actions/patients'
import { Version } from 'components/Version/Version'
import { AuthenticationService, Translations } from 'services'
import { getWindowDimensions } from 'utils/getWindowDimensions'

import './Sidebar.css'

export default function Sidebar (): JSX.Element {
  const [sideBarVisible, setSideBarVisible] = useState(getWindowDimensions().width > 830)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogout = (e): void => {
    e.preventDefault()

    AuthenticationService.logout()
    dispatch(cleanPatientsState())
    history.push('/')
  }

  return (
    <>
      <button
        className='BurgerButton'
        onClick={() => setSideBarVisible(!sideBarVisible)}
      >
        <i className='bars icon' />
      </button>
      <div className={classnames('Sidebar ui left vertical inverted sidebar labeled icon menu', {
        dimmed: !sideBarVisible,
        visible: sideBarVisible,
        'Sidebar--hidden': !sideBarVisible,
        'Sidebar--visible': sideBarVisible
      })}>
        <div className='item Sidebar__logo-wrapper'>
          <img src={process.env.PUBLIC_URL + '/assets/be-pacient.png'} alt='Be Pacient' />
        </div>
        <div className='item'>
          {/* @ts-expect-error TODO return value of getUserData not properly typed */}
          <p>{Translations.translate('SIDEBAR.WELCOME')}{AuthenticationService.getUserData().name}</p>
        </div>
        {AuthenticationService.isAdmin() && <Link className='item' to='/admin'>Admin</Link>}
        <Link className='item' to='/patients'>{Translations.translate('SIDEBAR.MY_PATIENTS')}</Link>
        <Link className='item' to='/patients/add'>{Translations.translate('SIDEBAR.ADD_PATIENT')}</Link>
        <Link className='item' to='/profile'>{Translations.translate('SIDEBAR.MY_PROFILE')}</Link>
        <Link className='item' to='/login' onClick={handleLogout}>{Translations.translate('SIDEBAR.LOGOUT')}</Link>
        <div className='item item--bottom'>
          <img src={process.env.PUBLIC_URL + '/assets/be-pacient-name.png'} alt='Be Pacient' />
          <Version />
        </div>
      </div>
    </>
  )
}
