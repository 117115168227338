export default (state = { show: false }, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return Object.assign({}, state, { modalData: action.payload, show: true })
    case 'HIDE_MODAL':
      return Object.assign({}, state, { show: false })
    default:
      return state
  }
}
