import React from 'react'
import { Translations } from 'services'

import './PasswordChangeForm.css'

class PasswordChangeForm extends React.Component {
  render () {
    const { handleInputChange, handleSubmit, passwordMatch } = this.props

    return (
      <div className='PasswordChangeForm'>
        <div className='ui stackable two column divided grid container'>
          <div className='column'>
            <form className='ui form' onSubmit={(e) => handleSubmit(e)}>
              <div className='field'>
                <label>{Translations.translate('PASSWORD_CHANGE.CURRENT_PASSWORD')}</label>
                <input
                  type='password'
                  name='currentPassword'
                  placeholder={Translations.translate('PASSWORD_CHANGE.CURRENT_PASSWORD')}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className='field'>
                <label>{Translations.translate('PASSWORD_CHANGE.NEW_PASSWORD')}</label>
                <input
                  type='password'
                  name='newPassword'
                  placeholder={Translations.translate('PASSWORD_CHANGE.NEW_PASSWORD')}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className='field'>
                <label>{Translations.translate('PASSWORD_CHANGE.REPEAT_NEW_PASSWORD')}</label>
                <input
                  type='password'
                  name='repeatPassword'
                  placeholder={Translations.translate('PASSWORD_CHANGE.REPEAT_NEW_PASSWORD')}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <button className='ui button' type='submit' disabled={!passwordMatch}>
                {Translations.translate('PASSWORD_CHANGE.SUBMIT')}
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default PasswordChangeForm
