export const showContextMenu = (data) => dispatch => {
  dispatch({
    type: 'SHOW_CONTEXT_MENU',
    payload: data
  })
}

export const hideContextMenu = () => dispatch => {
  dispatch({
    type: 'HIDE_CONTEXT_MENU'
  })
}
