import React from 'react'
import ReactGA from 'react-ga'
import BaseView from 'components/BaseView/BaseView'
import { AuthenticationService, Translations, UsersService } from 'services'
import PasswordChangeForm from 'components/PasswordChangeForm/PasswordChangeForm'
import OdontogramTreatmentColorSelector from 'components/OdontogramTreatmentColorSelector'
import { connect } from 'react-redux'
import { changeLanguage, getUserPreferences as getPreferences } from 'actions/users'
import { getUserLanguage, getUserPreferences } from 'selectors/users'
import { formatDate } from 'utils/formatDate'
import { pushNotification } from 'actions/notifications'

class Profile extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      newPassword: '',
      currentPassword: '',
      repeatPassword: '',
      user: {}
    }
  }

  componentDidMount () {
    this.props.getUserPreferences()
    this.setState({ user: AuthenticationService.getUserData() })
  }

  handleInputChange (e) {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleLanguageChange (e) {
    const language = e.target.value

    this.props.changeLanguage(language).then(() => {
      window.location.reload()
    })
  }

  handleTreatmentColorSubmit (treatmentPreferences) {
    const { pushNotification } = this.props

    UsersService.updatePreferences({ treatments: treatmentPreferences })
      .then(response => {
        pushNotification({
          message: response.message,
          title: Translations.translate('SUCCESS'),
          type: 'success'
        })
      })
      .catch(err => {
        console.error(err)

        pushNotification({
          message: err.message,
          title: Translations.translate('ERROR'),
          type: 'error'
        })
      })
  }

  handleSubmit (e) {
    e.preventDefault()
    const { currentPassword, newPassword, repeatPassword, user } = this.state
    const { pushNotification } = this.props

    AuthenticationService.changePassword(user._id, { currentPassword, newPassword, repeatPassword })
      .then(response => {
        pushNotification({
          message: response.message,
          title: Translations.translate('SUCCESS'),
          type: 'success'
        })

        ReactGA.event({
          category: 'Profile',
          action: 'User changed password successfully',
          label: user.email
        })

        this.resetState()
      })
      .catch(err => {
        console.error(err)

        pushNotification({
          message: err.message,
          title: Translations.translate('ERROR'),
          type: 'error'
        })
      })
  }

  passwordMatch () {
    const { newPassword, repeatPassword } = this.state

    return (newPassword !== '' && (newPassword === repeatPassword))
  }

  resetState () {
    this.setState({
      currentPassword: '',
      newPassword: '',
      repeatPassword: ''
    })
  }

  render () {
    const { user } = this.state
    const { userPreferences } = this.props
    const userLanguage = this.props.userLanguage || AuthenticationService.getUserLanguage()

    return (
      <div className='Profile'>
        <BaseView title={user.name}>
          <p>{user.name} - {user.email} {(user.role === 'admin') ? <i className='star outline icon' /> : null}</p>
          <p>{Translations.translate('PROFILE.MEMBER_SINCE')}: {formatDate(user.createdAt)}</p>
          <h4 className='ui horizontal divider header'>
            <i className='key icon' />
            {Translations.translate('PROFILE.CHANGE_PASSWORD')}
          </h4>
          <div>
            <PasswordChangeForm
              handleInputChange={this.handleInputChange.bind(this)}
              handleSubmit={this.handleSubmit.bind(this)}
              passwordMatch={this.passwordMatch()}
            />
          </div>
          <h4 className='ui horizontal divider header'>
            <i className='language icon' />
            {Translations.translate('PROFILE.CHANGE_LANGUAGE')}
          </h4>
          <div className='ui form'>
            <div className='inline fields'>
              <div className='field'>
                <div className='ui radio checkbox'>
                  <input
                    type='radio'
                    name='language'
                    value='EN'
                    checked={userLanguage === 'EN'}
                    onChange={this.handleLanguageChange.bind(this)}
                  />
                  <label>
                    <i className='en gb flag' />
                    {Translations.translate('PROFILE.ENGLISH')}
                  </label>
                </div>
              </div>
              <div className='field'>
                <div className='ui radio checkbox'>
                  <input
                    type='radio'
                    name='language'
                    value='ES'
                    checked={userLanguage === 'ES'}
                    onChange={this.handleLanguageChange.bind(this)}
                  />
                  <label>
                    <i className='es flag' />
                    {Translations.translate('PROFILE.SPANISH')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <h4 className='ui horizontal divider header'>
            <i className='language icon' />
            {Translations.translate('PROFILE.ODONTOGRAM_PREFERENCES')}
          </h4>
          <OdontogramTreatmentColorSelector
            handleSubmit={this.handleTreatmentColorSubmit.bind(this)}
            userTreatmentPreferences={userPreferences.treatments}
          />
        </BaseView>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  changeLanguage: (language) => dispatch(changeLanguage(language)),
  getUserPreferences: () => dispatch(getPreferences()),
  pushNotification: (notification) => dispatch(pushNotification(notification))
})
const mapStateToProps = state => ({
  ...state,
  userLanguage: getUserLanguage(state),
  userPreferences: getUserPreferences(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
