import React, { useEffect, useState } from 'react'
import { capitalizeString } from 'utils'
import { Translations as t } from 'services'
import './styles.css'

const COLORS = ['blue', 'red', 'green', 'yellow']

function OdontogramTreatmentColorSelector ({
  userTreatmentPreferences,
  handleSubmit: handleTreatmentColorSubmit
}) {
  const [existingColor, setExistingColor] = useState(null)
  const [pendingColor, setPendingColor] = useState(null)

  useEffect(() => {
    setExistingColor(userTreatmentPreferences?.existing)
    setPendingColor(userTreatmentPreferences?.pending)
  }, [userTreatmentPreferences])

  const handleExistingColorChange = (color) => {
    if (color !== pendingColor) {
      setExistingColor(color)
    }
  }
  const handlePendingColorChange = (color) => {
    if (color !== existingColor) {
      setPendingColor(color)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    handleTreatmentColorSubmit({
      existing: existingColor,
      pending: pendingColor
    })
  }

  return (
    <form className='ui form' onSubmit={(e) => handleSubmit(e)}>
      <div className='ui stackable four column divided grid container'>
        <div className='column'>
          <p>{t.translate('PROFILE.PENDING_WORK')}</p>
          {COLORS.map((color, index) => (
            <div key={index} className='field'>
              <div className='ui radio checkbox' onClick={() => handlePendingColorChange(color)}>
                <input
                  type='radio'
                  name='pending-treatment-color'
                  checked={pendingColor === color}
                  tabIndex='0'
                  className='hidden'
                  disabled={existingColor === color}
                />
                <label>{capitalizeString(color)}</label>
              </div>
            </div>
          ))}
        </div>
        <div className='column'>
          <p>{t.translate('PROFILE.EXISTING_WORK')}</p>
          {COLORS.map((color, index) => (
            <div key={index} className='field'>
              <div className='ui radio checkbox' onClick={() => handleExistingColorChange(color)}>
                <input
                  type='radio'
                  name='existing-treatment-color'
                  checked={existingColor === color}
                  tabIndex='0'
                  className='hidden'
                  disabled={pendingColor === color}
                />
                <label>{capitalizeString(color)}</label>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        className='OdontogramTreatmentColorSelector__submit-button ui button'
        type='submit'
        disabled={!existingColor || !pendingColor}
      >
        {t.translate('PROFILE.SAVE')}
      </button>
    </form>
  )
}

export default OdontogramTreatmentColorSelector
