import React from 'react'
import classNames from 'classnames'

export default class Zigzag extends React.Component {
  generateZigzagLines () {
    const LENGTH = 17
    const START_LINE = [[7, 1], [14, 2]]
    const zigzag = []
    const { color } = this.props

    for (let i = 0; i <= LENGTH; i++) {
      zigzag.push(
        <line
          x1={(i & 1) ? START_LINE[0][0] : START_LINE[1][0]}
          y1={START_LINE[0][1] + i}
          x2={(i & 1) ? START_LINE[1][0] : START_LINE[0][0]}
          y2={START_LINE[1][1] + i}
          stroke={color}
          strokeWidth='0.7'
        />
      )
    }

    return zigzag
  }

  render () {
    return (
      <svg className={classNames({ reference: this.props.reference })}>
        {this.generateZigzagLines().map(line => line)}
      </svg>
    )
  }
}
