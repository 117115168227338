import React from 'react'
import { connect } from 'react-redux'
import BaseView from 'components/BaseView/BaseView'
import Circle from 'components/Circle/Circle'
import HalfRectangle from 'components/HalfRectangle/HalfRectangle'
import Rectangle from 'components/Rectangle/Rectangle'
import X from 'components/X/X'
// import Notification from 'components/Notification/Notification'
import { Version } from 'components/Version/Version'
import { showModal } from 'actions/modal'

const Demo = (props) => {
  return (
    <div className='Demo'>
      <BaseView title='Demo'>
        <div>
          <button className='ui button' onClick={() => props.showModal()}>
            Show empty modal
          </button>
          <button
            className='ui button' onClick={() => props.showModal({
              mainMessage: 'Main message',
              message: 'Message',
              hasActions: false
            })}
          >
            Show modal without title
          </button>
          <button
            className='ui button' onClick={() => props.showModal({
              title: 'Title',
              mainMessage: 'Main message',
              message: 'Message',
              hasActions: false
            })}
          >
            Show modal without action buttons
          </button>
          <button
            className='ui button' onClick={() => props.showModal({
              title: 'Title',
              mainMessage: 'Main message',
              message: 'Message',
              hasActions: true
            })}
          >
            Show modal with action buttons
          </button>
        </div>
        <div>
          <h3 className='ui header'>Circle component</h3>
          <Circle color='blue' />
        </div>
        <div>
          <h3 className='ui header'>HalfRectangle component</h3>
          <HalfRectangle color='blue' />
        </div>
        <div>
          <h3 className='ui header'>Rectangle component</h3>
          <Rectangle color='red' />
        </div>
        <div>
          <h3 className='ui header'>X component</h3>
          <X color='blue' />
        </div>
        <div>
          <Version />
        </div>
      </BaseView>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalData = {}) => dispatch(showModal(modalData))
})
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(Demo)
