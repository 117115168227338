import React from 'react'
import classNames from 'classnames'

export default class Circle extends React.Component {
  render () {
    const { color, reference } = this.props

    return (
      <svg className={classNames({ reference })}>
        <circle cx='10' cy='10' r='10' stroke={color} strokeWidth='1' fill='transparent' />
      </svg>
    )
  }
}
