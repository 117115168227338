import React from 'react'
import { connect } from 'react-redux'
import { Translations as t } from 'services'
import PropTypes from 'prop-types'
import { selectExtra } from 'actions/extras'
import ExtraItem from 'components/ExtraItem/ExtraItem'
import X from 'components/X/X'
import Circle from 'components/Circle/Circle'
import HalfRectangle from 'components/HalfRectangle/HalfRectangle'
import Rectangle from 'components/Rectangle/Rectangle'
import Line from 'components/Line/Line'
import Zigzag from 'components/Zigzag/Zigzag'
import SE from 'components/SE/SE'
import TC from 'components/TC/TC'
import './Extras.css'

const items = [
  { key: 1, extraKey: 'X', name: t.translate('PIECES.MISSING_PIECE'), component: 'X' },
  { key: 2, extraKey: 'C', name: t.translate('PIECES.CROWN'), component: 'Circle' },
  { key: 3, extraKey: 'HR', name: t.translate('PIECES.BRIDGE'), component: 'HalfRectangle' },
  { key: 4, extraKey: 'R', name: t.translate('PIECES.PROSTHESIS'), component: 'Rectangle' },
  { key: 5, extraKey: 'L', name: t.translate('PIECES.BOLT'), component: 'Line' },
  { key: 6, extraKey: 'Z', name: t.translate('PIECES.IMPLANT'), component: 'Zigzag' },
  { key: 7, extraKey: 'SE', name: t.translate('PIECES.SEALANT'), component: 'SE' },
  { key: 8, extraKey: 'TC', name: t.translate('PIECES.ROOT_CANAL'), component: 'TC' }
]

class Extras extends React.Component {
  renderItem (item) {
    /* eslint-disable react/jsx-pascal-case */
    const itemsMap = {
      X: <X color='black' reference />,
      Circle: <Circle color='black' reference />,
      HalfRectangle: <HalfRectangle color='black' reference />,
      Rectangle: <Rectangle color='black' reference />,
      Line: <Line color='black' reference />,
      Zigzag: <Zigzag color='black' reference />,
      SE: <SE color='black' reference />,
      TC: <TC color='black' reference />
    }
    /* eslint-enable react/jsx-pascal-case */

    return (
      <div className='Extras__extras-item-image-wrapper'>
        {itemsMap[item]}
      </div>
    )
  }

  render () {
    const { selectExtra, selectedExtra } = this.props

    return (
      <div className='ui card middle aligned animated selection list'>
        <div className='content'>
          <p>{t.translate('ODONTOGRAM.SELECT_JOB')}</p>
        </div>
        {items.map((item, key) => (
          <div
            key={key}
            className='item content Extras__extras-item-wrapper'
            onClick={() => selectExtra(item.extraKey)}
          >
            {this.renderItem(item.component)}
            <div className='Extras__extras-item-name-wrapper content'>
              <div className='header'>
                <ExtraItem key={item.key} extraKey={item.extraKey} name={item.name} imgSrc={item.imgSrc} />
              </div>
              {selectedExtra === item.extraKey && (
                <div className='Extras__extra-selected'>
                  <i className='check icon' />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

Extras.propTypes = {
  selectedExtra: PropTypes.string.isRequired
}

const mapDispatchToProps = dispatch => ({ selectExtra: (extraKey) => dispatch(selectExtra(extraKey)) })
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(Extras)
