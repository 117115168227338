import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { uploadFile } from 'actions/files'
import { Translations } from 'services'

import './FileUploader.css'

class FileUploader extends React.Component {
  constructor () {
    super()

    this.state = {
      file: null,
      fileDescription: ''
    }
    this.descriptionInput = ''
    this.fileInput = null
  }

  handleDescriptionChange (event) {
    this.setState({
      fileDescription: event.target.value
    })
  }

  handleFileChange (event) {
    this.setState({
      file: event.target.files[0]
    })
  }

  handleUploadFile () {
    const { patientId, type, uploadFile } = this.props
    const { file, fileDescription } = this.state
    const data = new FormData()

    data.append('file', file)
    data.append('name', file.name)
    data.append('description', fileDescription)

    uploadFile(data, patientId, type)

    // reset input value after submitting
    this.fileInput.value = null
    this.descriptionInput.value = ''
  }

  render () {
    const selectedFile = this.fileInput && this.fileInput.value
    const selectedFileName = selectedFile && selectedFile.split('\\')[2]

    return (
      <div className='FileUploader'>
        <div className='ui small form'>
          <div className='fields'>
            <div className='FileUploader__file-input-container four wide field'>
              <label htmlFor='patient-image' className='ui button'>
                {selectedFileName || Translations.translate('FILE_UPLOADER.CHOOSE_FILE')}
              </label>
              <input
                id='patient-image'
                type='file'
                name='patient-image'
                className='FileUploader__file-input'
                accept={this.props.types.join(',')}
                onChange={this.handleFileChange.bind(this)}
                ref={ref => {
                  this.fileInput = ref
                }}
              />
            </div>
            <div className='twelve wide field'>
              <input
                placeholder={Translations.translate('FILE_UPLOADER.DESCRIPTION')}
                type='text'
                onChange={this.handleDescriptionChange.bind(this)}
                ref={ref => {
                  this.descriptionInput = ref
                }}
              />
            </div>
          </div>
          <button
            className='ui submit primary button'
            onClick={this.handleUploadFile.bind(this)}
            disabled={!this.state.file}
          >
            {Translations.translate('FILE_UPLOADER.UPLOAD')}
          </button>
        </div>
      </div>
    )
  }
}

FileUploader.propTypes = {
  types: PropTypes.array.isRequired,
  patientId: PropTypes.string.isRequired,
  type: PropTypes.string
}

const mapDispatchToProps = dispatch => ({
  uploadFile: (fileData, patientId, type) => dispatch(uploadFile(fileData, patientId, type))
})
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader)
