// import { begin, end, pendingTask } from 'react-redux-spinner'
import { Translations } from 'services'
import { pushNotification } from 'actions/notifications'

export const loginRequest = (loginData) => dispatch => {
  dispatch({
    type: 'LOGIN_REQUEST',
    payload: loginData
    // [pendingTask]: begin
  })
}

export const loginSuccess = (loginResponse) => dispatch => {
  dispatch({
    type: 'LOGIN_SUCCESS',
    payload: loginResponse
    // [pendingTask]: end
  })
}

export const loginFailure = (loginResponse) => dispatch => {
  dispatch(pushNotification({
    type: 'error',
    title: Translations.translate('ERROR'),
    message: loginResponse.message
  }))

  dispatch({
    type: 'LOGIN_FAILURE',
    payload: loginResponse
  })
}

export const logout = () => dispatch => {
  dispatch({
    type: 'LOGOUT'
  })
}
