export default (state = {}, action) => {
  switch (action.type) {
    case 'SELECT_TREATMENT_TYPE':
      return {
        ...state,
        selectedType: action.payload
      }
    default:
      return state
  }
}
