export const showModal = (modalConfig) => dispatch => {
  dispatch({
    type: 'SHOW_MODAL',
    payload: modalConfig
  })
}

export const hideModal = () => dispatch => {
  dispatch({
    type: 'HIDE_MODAL'
  })
}
