import BaseHttpService from './baseHttpService'

class OdonthogramsService {
  constructor () {
    this.apiV1 = BaseHttpService.getApiV1Url()
  }

  addObservation (odonthogramId, observation) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify(observation)
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/odonthogram/${odonthogramId}/observations`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  getById (odonthogramId) {
    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/odonthogram/${odonthogramId}`, BaseHttpService.getOptions())
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  updateOdonthogram (odonthogramId, data) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'put',
      body: JSON.stringify({ ...data })
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/odonthogram/${odonthogramId}`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  async removeObservation (odonthogramId, observationId) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'delete'
    }

    try {
      const rawResponse = await fetch(
        `${this.apiV1}/odonthogram/${odonthogramId}/observations/${observationId}`,
        options
      )
      const response = await rawResponse.json()

      if (!response.success) throw response

      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default new OdonthogramsService()
