import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import BaseView from 'components/BaseView/BaseView'
import { Translations, UsersService } from 'services'

const Verify = () => {
  useEffect(() => {
    (async () => {
      const path = window.location.pathname

      ReactGA.set({ page: path })
      ReactGA.pageview(path)
      const params = new URLSearchParams(window.location.search)
      const verification = await UsersService.verify(params.get('email'), params.get('hash'))

      if (verification.success) {
        window.location.replace('/login')
      } else {
        setError(verification.message)
      }
    })()
  }, [])

  const [error, setError] = useState(null)

  return (
    <BaseView
      title={Translations.translate('VERIFY.VERIFY_ACCOUNT')}
      withSidebar={false}
    >
      <h4>{Translations.translate('VERIFY.VERIFYING_ACCOUNT')}</h4>
      {Boolean(error) && (
        <p className='ui message error'>
          {error}{' '}
          <a href='/register'>{Translations.translate('LOGIN.REGISTER')}</a>
        </p>
      )}
    </BaseView>
  )
}

export default Verify
