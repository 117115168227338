import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import { PatientsService, Translations, Utils } from 'services'
import { changePatientPage, createPatient, createPatientSuccess, createPatientFailure } from 'actions/patients'
import { setObservations } from 'actions/pieces'
import { pushNotification } from 'actions/notifications'
import BaseView from 'components/BaseView/BaseView'
import PatientForm from 'components/PatientForm/PatientForm'
import Steps from 'components/Steps/Steps'
import TreatmentForm from 'components/TreatmentForm/TreatmentForm'

class AddPatient extends React.Component {
  constructor (props) {
    super(props)

    this._currentTab = 'information'
    this._currentPatient = ''
  }

  set currentTab (tab) {
    this._currentTab = tab
  }

  get currentTab () {
    return this._currentTab
  }

  set currentPatient (patient) {
    this._currentPatient = patient
  }

  get currentPatient () {
    return this._currentPatient
  }

  UNSAFE_componentWillMount () {
    this.readQueryStringAndSetTab()
  }

  componentDidUpdate () {
    this.readQueryStringAndSetTab()
  }

  readQueryStringAndSetTab () {
    const queryStringParams = queryString.parse(this.props.location.search)
    const { patient, tab } = queryStringParams

    if (patient) {
      this.currentPatient = patient
    }

    if (tab) {
      this.currentTab = tab
    }
  }

  handleInputChange (e) {
    const { name, value } = e.target
    let newState = {}

    if (name.indexOf('.') > -1) {
      const objectFromInput = Utils.dotSeparatedToObject(name, value)
      const objectFromInputKey = Object.keys(objectFromInput)[0]

      if (Object.keys(this.state).indexOf(objectFromInputKey) > -1) {
        newState = {
          [objectFromInputKey]: Object.assign({}, this.state[objectFromInputKey], objectFromInput[objectFromInputKey])
        }
      } else {
        newState = Object.assign({}, this.state, Utils.dotSeparatedToObject(name, value))
      }
    } else {
      newState = { [name]: value }
    }

    this.setState(newState)
  }

  handleTreatementCreationResponse (response) {
    const { history, pushNotification } = this.props

    history.push({ pathname: '/patients' })

    pushNotification({
      message: response.message,
      title: Translations.translate('SUCCESS'),
      type: 'success'
    })
  }

  handleTreatmentSubmit (e) {
    e.preventDefault()

    const { pieces, pushNotification } = this.props

    PatientsService
      // TODO: submit only from pieces the needed stuff, not all the data comming from the state
      .createTreatment(pieces.odonthogram.data, this.currentPatient)
      .then(response => this.handleTreatementCreationResponse(response))
      .catch(err => {
        console.error(err)

        pushNotification({
          message: err.message,
          title: Translations.translate('ERROR'),
          type: 'error'
        })
      })
  }

  handlePatientCreationResponse (response) {
    const { data: createdUser } = response
    const { changePatientPage, createPatientSuccess, history, pushNotification } = this.props

    createPatientSuccess(response)

    history.push({
      pathname: `/patients/${createdUser._id}/odonthogram/${createdUser.odonthograms[0]}/edit`
    })

    changePatientPage('treatment')

    pushNotification({
      message: response.message,
      title: Translations.translate('SUCCESS'),
      type: 'success'
    })
  }

  handlePatientCreation (e) {
    e.preventDefault()

    const { createPatient, createPatientFailure, pushNotification } = this.props
    const state = this.state

    if (state) {
      createPatient(state)

      PatientsService.createPatient(state)
        .then(response => this.handlePatientCreationResponse(response))
        .catch(err => {
          createPatientFailure(err)

          pushNotification({
            message: err.data.message ?? err.message,
            title: Translations.translate('ERROR'),
            type: 'error'
          })
        })
    }
  }

  renderPage () {
    let page

    switch (this.currentTab) {
      case 'information': {
        page = this.renderPatientForm()
        break
      }
      case 'treatment': {
        page = this.renderTreatmentForm()
        break
      }
      default:
        page = <div />
    }

    return page
  }

  renderPatientForm () {
    return (
      <PatientForm
        handleInputChange={this.handleInputChange.bind(this)}
        handleSubmit={this.handlePatientCreation.bind(this)}
      />
    )
  }

  renderTreatmentForm () {
    return (
      <TreatmentForm handleSubmit={this.handleTreatmentSubmit.bind(this)} page='addPatient' />
    )
  }

  render () {
    return (
      <BaseView title={Translations.translate('ADD_PATIENT.ADD_PATIENT')}>
        <Steps
          currentPage={this.currentTab}
        />
        {this.renderPage()}
      </BaseView>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  changePatientPage: (page) => dispatch(changePatientPage(page)),
  createPatient: (patient) => dispatch(createPatient(patient)),
  createPatientFailure: (err) => dispatch(createPatientFailure(err)),
  createPatientSuccess: (response) => dispatch(createPatientSuccess(response)),
  setObservations: (observations) => dispatch(setObservations(observations)),
  pushNotification: (notification) => dispatch(pushNotification(notification))
})
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPatient))
