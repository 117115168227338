import React from 'react'
import classNames from 'classnames'

export default class Line extends React.Component {
  render () {
    const { color, reference } = this.props

    return (
      <svg className={classNames({ reference })}>
        <line x1='10' y1='0' x2='10' y2='20' stroke={color} strokeWidth='1' />
      </svg>
    )
  }
}
