import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Pagination as PaginationComponent,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious
} from 'components/ui/pagination'
import { getPatientsRequest, getPatientsTablePage, getTotalPatients } from 'selectors/patients'
import { getPatients } from 'actions/patients'
import { Translations } from 'services'

const PATIENTS_PER_PAGE = 15

export const Pagination: React.FC = () => {
  const dispatch = useDispatch()
  const currentPage = useSelector(getPatientsTablePage)
  const patientsRequest = useSelector(getPatientsRequest)
  const totalPatients = useSelector(getTotalPatients)
  const totalPages = Math.floor(totalPatients / PATIENTS_PER_PAGE)
  const isNextDisabled = currentPage === totalPages
  const isPrevDisabled = currentPage === 1

  const handlePageChange = useCallback((dir: 'next' | 'prev') => {
    const page = dir === 'next' ? currentPage + 1 : currentPage - 1

    dispatch(getPatients({
      ...patientsRequest,
      page
    }))
  }, [currentPage, dispatch, patientsRequest])

  const handleNext = useCallback(() => {
    if (!isNextDisabled) {
      handlePageChange('next')
    }
  }, [isNextDisabled, handlePageChange])

  const handlePrev = useCallback(() => {
    if (!isPrevDisabled) {
      handlePageChange('prev')
    }
  }, [isPrevDisabled, handlePageChange])

  return (
    <div className="flex items-center">
      <PaginationComponent className="w-auto">
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              className={isPrevDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
              label={Translations.translate('PREVIOUS')}
              onClick={handlePrev}
            />
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              className={isNextDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
              label={Translations.translate('NEXT')}
              onClick={handleNext}
            />
          </PaginationItem>
        </PaginationContent>
      </PaginationComponent>
      <p>{currentPage}/{totalPages} Total: {totalPatients}</p>
    </div>
  )
}
