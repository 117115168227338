import React from 'react'
import { BaseView, TreatmentForm } from 'components'
import { getPatient } from 'actions/patients'
import { getOdonthogram, updateOdonthogram } from 'actions/pieces'
import { Translations as t } from 'services'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class EditPatientOdonthogram extends React.Component {
  componentDidMount () {
    const { fetchPatient, getOdonthogram, match } = this.props
    const { odonthogramId, patientId } = match.params

    fetchPatient(patientId)
    getOdonthogram(odonthogramId)
  }

  handleOdonthogramSubmit () {
    const { match, pieces, updateOdonthogram } = this.props
    const { odonthogramId } = match.params
    const { __v, _id, createdAt, createdBy, observations, updatedAt, ...odonthogramData } = pieces.odonthogram.data

    updateOdonthogram(odonthogramId, odonthogramData)
  }

  render () {
    const title = t.translate('PATIENTS.EDIT_ODONTOGRAM')
    const { patients } = this.props

    return (
      <BaseView title={title}>
        <h4>{t.translate('PATIENTS.PATIENT')}: {patients.patient.name}</h4>
        <TreatmentForm
          handleSubmit={this.handleOdonthogramSubmit.bind(this)}
          page='editPatientOdonthogram'
        />
      </BaseView>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPatient: (patientId) => dispatch(getPatient(patientId)),
  getOdonthogram: (odonthogramId) => dispatch(getOdonthogram(odonthogramId)),
  updateOdonthogram: (odonthogramId, odonthogramData) => dispatch(updateOdonthogram(odonthogramId, odonthogramData))
})
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditPatientOdonthogram))
