import BaseHttpService from './baseHttpService'

type Features = {
  patientPDFGeneration: boolean
}

export type OldProfileImage = {
  _id: string
  type: 'profile' | 'image'
  active: boolean
  base64Image: string
  name: string
  uploadedBy: string
  patient: string
  createdAt: Date
  updatedAt: Date
}

class AdminService {
  constructor () {
    this.v1Url = BaseHttpService.getApiV1Url()
    this.v3Url = BaseHttpService.getApiV3Url()
  }

  v1Url: string

  v3Url: string

  async getFeatures (): Promise<Features> {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'get'
    }
    const response = await fetch(
      `${this.v1Url}/admin/features`,
      options
    )
    return await response.json()
  }

  async getProfileImages (): Promise<OldProfileImage[]> {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'get'
    }
    const response = await fetch(
      `${this.v1Url}/admin/profile-images`,
      options
    )
    return await response.json()
  }

  async getImages (): Promise<OldProfileImage[]> {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'get'
    }
    const response = await fetch(
      `${this.v1Url}/admin/images`,
      options
    )
    return await response.json()
  }

  async migrateProfileImage (
    image: OldProfileImage,
    type: 'profile' | 'image'
  ): Promise<unknown> {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify(image)
    }

    const response = await fetch(
      `${this.v3Url}/files/upload-base64-image?type=${type}&patient=${image.patient}&uploadedBy=${image.uploadedBy}&fromBase64=true`,
      options
    )
    return await response.json()
  }

  async setFeature (features: Record<string, any>): Promise<Features> {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify({ flags: features })
    }
    const response = await fetch(
      `${this.v1Url}/admin/features`,
      options
    )
    const json = await response.json()

    if (!response.ok) {
      throw json
    }

    return json.data
  }
}

export default new AdminService()
