import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import AddPatient from 'views/Patients/AddPatient'
import Admin from 'views/Admin/Admin'
import Demo from 'views/Demo/Demo'
import EditPatientInfo from 'views/Patients/EditPatientInfo'
import EditPatientOdonthogram from 'views/Patients/EditPatientOdonthogram'
import { Login } from 'views/Login'
import PatientDetail from 'views/Patients/PatientDetail'
import Patients from 'views/Patients/Patients'
import Profile from 'views/Profile/Profile'
import { Register } from 'views/Register'
import { ResetPassword } from 'views/ResetPassword/ResetPassword'
import Verify from 'views/Verify/Verify'
import {
  AdminRoute,
  ProtectedRoute,
  UnauthenticatedRoute,
  SwitchWithInterceptor
} from 'components/ProtectedRoute/ProtectedRoute'

function AppRouter () {
  return (
    <Router>
      <SwitchWithInterceptor>
        <UnauthenticatedRoute path='/register' component={Register} />
        <UnauthenticatedRoute path='/login' component={Login} />
        <UnauthenticatedRoute path='/:id/reset-password' component={ResetPassword} />
        <Route path='/verify' component={Verify} />
        <ProtectedRoute exact path='/' component={Patients} />
        <ProtectedRoute path='/profile' component={Profile} />
        <ProtectedRoute exact path='/patients' component={Patients} />
        <ProtectedRoute exact path='/patients/add' component={AddPatient} />
        <ProtectedRoute exact path='/patients/:id' component={PatientDetail} />
        <ProtectedRoute exact path='/patients/:id/edit-info' component={EditPatientInfo} />
        <ProtectedRoute
          exact path='/patients/:patientId/odonthogram/:odonthogramId/edit'
          component={EditPatientOdonthogram}
        />
        <AdminRoute exact path='/admin' component={Admin} />
        <AdminRoute exact path='/demo' component={Demo} />
      </SwitchWithInterceptor>
    </Router>
  )
}

export default AppRouter
