import React from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Translations as t } from 'services'
import { selectTreatmentType } from 'actions/treatmentType'
import { getUserOdontogramColors } from 'selectors/users'
import { getSelectedTreatmentType } from 'selectors/treatmentType'
import './TreatmentTypeSelector.css'

const COLORS = [
  {
    color: 'pending',
    description: t.translate('TREATMENT_TYPE_SELECTOR.PENDING_WORK')
  },
  {
    color: 'existing',
    description: t.translate('TREATMENT_TYPE_SELECTOR.EXISTING_WORK')
  }
]

export default function TreatmentTypeSelector () {
  const dispatch = useDispatch()
  const userOdontogramColors = useSelector(getUserOdontogramColors)
  const selectedTreatmentType = useSelector(getSelectedTreatmentType)
  const handleTypeClick = (color) => {
    dispatch(selectTreatmentType(color))
  }

  return (
    <div className='TreatmentTypeSelector ui card middle aligned animated selection list'>
      <div className='content'>
        <p>{t.translate('ODONTOGRAM.SELECT_TREATMENT_TYPE')}</p>
      </div>
      {COLORS.map(({ color, description }, key) => (
        <div
          key={key}
          className={classnames('item content TreatmentTypeSelector__treatment-type', {
            'TreatmentTypeSelector__treatment-type--selected': selectedTreatmentType === color
          })}
          onClick={() => handleTypeClick(color)}
        >
          <div className='TreatmentTypeSelector__treatment-type-wrapper'>
            <div className={`TreatmentTypeSelector__color TreatmentTypeSelector__color--${userOdontogramColors[color]}`} />
            <div className='content TreatmentTypeSelector__content'>
              {description}
              {selectedTreatmentType === color && (
                <div className='TreatmentTypeSelector__selected-icon'>
                  <i className='check icon' />
                </div>
              )}
            </div>
            {selectTreatmentType === color && <i className='check icon' />}
          </div>
        </div>
      ))}
    </div>
  )
}
