import type { Observation, OdontogramDocument } from '../types/odontogram'

type State = {
  error: string | null
  submitting: boolean
  odonthogram: {
    data?: OdontogramDocument
    success: boolean
  }
}

export function getPieces (state): State {
  return state.pieces
}

export function getObservations (state): Observation[] {
  return state.pieces.odonthogram?.data?.observations ?? []
}
