import React, { PropsWithChildren } from 'react'

export const AuthScreenWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='container flex h-screen relative flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0'>
      <div className='relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r'>
        <div className='absolute inset-0 bg-zinc-900' />
        <div className='relative z-20 flex items-center text-lg font-medium'>
          <img src='/assets/be-pacient.png' className='w-12 mr-2' alt='Be Pacient' />
          <img src='/assets/be-pacient-name.png' className='w-[100px] mr-2' alt='Be Pacient' />
        </div>
        <div className='relative z-20 mt-auto'>
          <blockquote className='space-y-2'>
            <p className='text-lg'>
              “I’m proud to be a dentist because I have the power to give people a reason to smile.”
            </p>
            <footer className='text-sm'>
              Unknown author
            </footer>
          </blockquote>
        </div>
      </div>
      <div className='lg:p-8'>
        {children}
      </div>
    </div>
  )
}
