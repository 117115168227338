import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { pushNotification } from 'actions/notifications'
import { Translations, UsersService } from 'services'

import './ResetPassword.css'

type Params = {
  id: string
}

export function ResetPassword (): JSX.Element {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<Params>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')

  const handleSubmit = useCallback(async (e): Promise<void> => {
    e.preventDefault()

    try {
      await UsersService.resetPassword(id, token, password)

      history.push('/login')
    } catch (error) {
      console.error(error)

      dispatch(pushNotification({
        message: error.message,
        title: Translations.translate('ERROR'),
        type: 'error'
      }))
    }
  }, [dispatch, history, id, password, token])

  return (
    <div className='ResetPassword ui center aligned grid'>
      <Helmet title='Be Pacient | Reset password' />
      <div className='ResetPassword__wrapper ui middle aligned grid'>
        <div className='column'>
          <h2 className='ui teal image header'>
            <img src={process.env.PUBLIC_URL + '/assets/be-pacient.png'} alt='Be Pacient' />
            <div className='content'>
              {Translations.translate('PASSWORD_CHANGE.RESET_PASSWORD')}
            </div>
          </h2>
          <form className='ui large form' onSubmit={handleSubmit}>
            <div className='ui stacked segment'>
              <div className='field'>
                <div className='ui left icon input'>
                  <i className='lock icon' />
                  <input
                    type='password'
                    name='password'
                    placeholder={Translations.translate('PASSWORD_CHANGE.NEW_PASSWORD')}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className='field'>
                <div className='ui left icon input'>
                  <i className='lock icon' />
                  <input
                    type='password'
                    name='re-password'
                    placeholder={Translations.translate('PASSWORD_CHANGE.REPEAT_NEW_PASSWORD')}
                    onChange={(e) => setRePassword(e.currentTarget.value)}
                  />
                </div>
              </div>
              <button className='ui fluid large teal submit button' disabled={password !== rePassword}>
                {Translations.translate('PASSWORD_CHANGE.SUBMIT')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
