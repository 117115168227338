import moment from 'moment'

export function formatDate (date: Date, withTime = false): string {
  let format = 'DD/MM/YYYY'

  if (withTime) {
    format += ' HH:mm'
  }

  return moment(date).utc().format(format)
}
