import React from 'react'
import { connect } from 'react-redux'
import { selectExtra } from 'actions/extras'
import './ExtraItem.css'

class ExtraItem extends React.Component {
  handleExtraClick () {
    const { extraKey, selectExtra } = this.props

    selectExtra(extraKey)
  }

  render () {
    return (
      <div className='ExtraItem item' onClick={this.handleExtraClick.bind(this)}>
        <div className='ExtraItem__wrapper content'>
          <div className='ExtraItem__name header'>{this.props.name}</div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({ selectExtra: (extraKey) => dispatch(selectExtra(extraKey)) })
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(ExtraItem)
