import React from 'react'
import Piece from 'components/Piece/Piece'

const T_ROW_PIECES = 8
const B_ROW_PIECES = 5

class PieceRow extends React.Component {
  getPieceIndex (index) {
    const props = this.props
    let pieceIndex

    if (props.rowPosition === 'L') {
      const piecesQuantity = (props.rowType === 'B') ? B_ROW_PIECES : T_ROW_PIECES

      pieceIndex = ((parseInt(props.quadrant) * 10) + piecesQuantity) - index
    } else {
      pieceIndex = ((index + 1) + (parseInt(props.quadrant) * 10))
    }

    return pieceIndex
  }

  getPieceProps (index) {
    const { page, readOnly } = this.props

    return {
      key: index,
      translateTransform: `translate(${this.xAxisTranslate},${this.yAxisTranslate})`,
      page,
      readOnly
    }
  }

  renderPieceRow () {
    const pieces = []
    let piecesQuantity

    // eslint-disable-next-line
    switch (this.props.rowPosition) {
      case 'L':
        this.xAxisTranslate = 0
        break
      case 'R':
        this.xAxisTranslate = 210
        break
    }

    // eslint-disable-next-line
    switch (this.props.rowId) {
      case '1':
        this.yAxisTranslate = 0
        break
      case '2':
        this.yAxisTranslate = 40
        break
      case '3':
        this.yAxisTranslate = 80
        break
      case '4':
        this.yAxisTranslate = 120
        break
    }

    // eslint-disable-next-line
    switch (this.props.rowType) {
      case 'T':
        piecesQuantity = T_ROW_PIECES
        break
      case 'B':
        piecesQuantity = B_ROW_PIECES
        break
    }

    if (this.props.rowType === 'B' && this.props.rowPosition === 'R') {
      this.xAxisTranslate = 210
    } else if (this.props.rowType === 'B' && this.props.rowPosition === 'L') {
      this.xAxisTranslate = 75
    }

    for (let i = 0; i < piecesQuantity; i++) {
      if (i > 0) this.xAxisTranslate += 25
      pieces.push(<Piece pieceIndex={this.getPieceIndex(i)} {...this.getPieceProps(i)} />)
    }

    return pieces
  }

  render () {
    return (
      <g
        transform='scale(1.5)'
        data-quadrant={this.props.quadrant}
        data-row-id={this.props.rowId}
        data-row-type={this.props.rowType}
        data-row-position={this.props.rowPosition}
      >
        {this.renderPieceRow()}
      </g>
    )
  }
}

export default PieceRow
