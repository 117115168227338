import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Translations } from 'services'
import { hideModal } from 'actions/modal'
import { getImageById } from 'selectors/files'
import { getIsModalVisible, getModalData } from 'selectors/modal'

import './Modal.css'

// TODO: functional comp
class Modal extends React.Component {
  renderModal () {
    const {
      hideModal,
      modalData
    } = this.props

    return (
      <div className={classnames('ui small modal visible active', {
        Modal__container: modalData.isImage
      })}
      >
        <i className='close icon' onClick={hideModal} />
        {modalData.title && (
          <div className='header'>
            {modalData.title}
          </div>
        )}
        <div className='Modal__image-wrapper image content'>
          <div className='description'>
            <div className='ui header'>{modalData.mainMessage}</div>
            <p>{modalData.message}</p>
          </div>
          {modalData.isImage && <img className='Modal__image image' src={modalData.imgSrc} />}
        </div>
        {modalData.hasActions && (
          <div className='actions'>
            <div onClick={hideModal} className='ui black deny button'>
              {Translations.translate('MODAL.NO')}
            </div>
            <div onClick={() => modalData.handleAccept(modalData.patient._id)} className='ui positive right labeled icon button'>
              {Translations.translate('MODAL.YES')}
              <i className='checkmark icon' />
            </div>
          </div>
        )}
      </div>
    )
  }

  render () {
    const { hideModal, modalVisible } = this.props

    return (
      <div className={modalVisible ? 'Modal' : 'Modal--hidden'} onClick={hideModal}>
        {this.props.modalVisible && this.renderModal()}
      </div>
    )
  }
}

Modal.propTypes = {
  handleAccept: PropTypes.func,
  handleClose: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal())
})
const mapStateToProps = state => ({
  selectedImage: getImageById(state),
  modalVisible: getIsModalVisible(state),
  modalData: getModalData(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
