import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import debounce from 'lodash.debounce'

import { showContextMenu } from 'actions/contextMenu'
import { pushNotification } from 'actions/notifications'
import {
  deletePatient,
  getPatients,
  updatePatient
} from 'actions/patients'
import { hideModal, showModal } from 'actions/modal'
import BaseView from 'components/BaseView/BaseView'
import { PatientsTable } from 'components/PatientsTable/PatientsTable'
import { Translations } from 'services'
import { getError, getPatientsRequest } from 'selectors/patients'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'

type Patient = {
  _id: string
  name: string
  odonthograms: string[]
}

export default function Patients (): JSX.Element {
  const dispatch = useDispatch()
  const history = useHistory()
  const patientsRequest = useSelector(getPatientsRequest)
  const patientsError = useSelector(getError)

  useEffect(() => {
    dispatch(getPatients({}))
  }, [dispatch])

  useEffect(() => {
    if (patientsError != null) {
      console.error(patientsError)

      dispatch(pushNotification({
        message: typeof patientsError === 'string' ? patientsError : patientsError.message,
        title: Translations.translate('ERROR'),
        type: 'error'
      }))
    }
  }, [dispatch, patientsError])

  const debouncedGetPatients = debounce((filter: string) => {
    dispatch(getPatients({
      ...patientsRequest,
      page: 1,
      nameFilter: filter
    }))
  }, 500)

  const handlePatientsFilterChange = (e): void => {
    debouncedGetPatients(e.target.value)
  }

  const handleModalAccept = async (patientId: Patient['_id']): Promise<void> => {
    dispatch(updatePatient())

    const response = await dispatch(deletePatient(patientId))

    // TODO: to remove this move `actions/patients` to ts
    // @ts-expect-error eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response?.success) {
      dispatch(getPatients(patientsRequest))

      dispatch(hideModal())

      dispatch(pushNotification({
        // @ts-expect-error: TODO
        message: response.message,
        title: Translations.translate('SUCCESS'),
        type: 'success'
      }))
    }
  }

  const handleDeletePatient = (patient: Patient): void => {
    dispatch(showModal({
      mainMessage: Translations.translate('PATIENTS.ARE_YOU_SURE_TO_DELETE').replace('<<name>>', patient.name),
      message: Translations.translate('PATIENTS.PERMANENT_ACTION'),
      patient,
      title: Translations.translate('PATIENTS.DELETE_PATIENT'),
      hasActions: true,
      handleAccept: handleModalAccept
    }))
  }

  const handleShowContextMenu = (e, patient: Patient): void => {
    e.preventDefault()

    const contextMenuData = {
      data: patient,
      items: [
        {
          label: Translations.translate('PATIENTS.VIEW_PATIENT'),
          enabled: true,
          action: () => {
            history.push(`/patients/${patient._id}?tab=odonthogram`)
          }
        },
        {
          label: Translations.translate('PATIENTS.EDIT_PATIENT_INFORMATION'),
          enabled: true,
          action: () => {
            history.push(`/patients/${patient._id}/edit-info`)
          }
        },
        {
          label: Translations.translate('PATIENTS.EDIT_ODONTOGRAM'),
          enabled: !(patient.odonthograms.length === 0),
          action: () => {
            history.push(`/patients/${patient._id}/odonthogram/${patient.odonthograms[0]}/edit`)
          }
        },
        {
          label: Translations.translate('PATIENTS.DELETE_PATIENT'),
          enabled: true,
          action: () => handleDeletePatient(patient)
        }
      ],
      position: {
        x: e.clientX,
        y: e.clientY
      }
    }

    dispatch(showContextMenu(contextMenuData))
  }

  return (
    <BaseView title={Translations.translate('PATIENTS.MY_PATIENTS')}>
      <>
        <div className='flex justify-between items-center mb-2'>
          <Input
            type='text'
            placeholder={Translations.translate('PATIENTS_FILTER.SEARCH')}
            className='w-60'
            onChange={handlePatientsFilterChange}
          />
          <Button
            onClick={() => {
              history.push('/patients/add')
            }}
          >
            <i className='user icon' /> {Translations.translate('PATIENTS_TABLE.ADD_PATIENT')}
          </Button>
        </div>
        <PatientsTable showContextMenu={handleShowContextMenu} />
      </>
    </BaseView>
  )
}
