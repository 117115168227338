type Dimensions = {
  height: number
  width: number
}

export function getWindowDimensions (): Dimensions {
  return {
    height: window.innerHeight,
    width: window.innerWidth
  }
}
