import React from 'react'
import Polygon from 'components/Polygon/Polygon'
import Circle from 'components/Circle/Circle'
import HalfRectangle from 'components/HalfRectangle/HalfRectangle'
import Line from 'components/Line/Line'
import Rectangle from 'components/Rectangle/Rectangle'
import SE from 'components/SE/SE'
import TC from 'components/TC/TC'
import X from 'components/X/X'
import Zigzag from 'components/Zigzag/Zigzag'
import { connect } from 'react-redux'
import { applyExtra } from 'actions/pieces'
import { getSelectedTreatmentColorByType, getSelectedTreatmentType } from 'selectors/treatmentType'
import './Piece.css'

const faces = {
  top: [[0, 0], [20, 0], [15, 5], [5, 5]],
  bottom: [[5, 15], [15, 15], [20, 20], [0, 20]],
  right: [[15, 5], [20, 0], [20, 20], [15, 15]],
  left: [[0, 0], [5, 5], [5, 15], [0, 20]],
  center: [[5, 5], [15, 5], [15, 15], [5, 15]]
}

class Piece extends React.Component {
  getOdonthogramData () {
    // TODO: pass the piece colors data from its parent
    const { page, pieces } = this.props
    let odonthogram = pieces

    if ((page === 'editPatientOdonthogram' || page === 'patientDetail' || page === 'addPatient') &&
      pieces.odonthogram.data) {
      odonthogram = pieces.odonthogram.data
    }

    return odonthogram
  }

  getPolygonProps (face) {
    const { getColorByType, pieceIndex, readOnly } = this.props
    const pieces = this.getOdonthogramData()
    const currentPiece = pieces[pieceIndex] || {}
    const fill = getColorByType(currentPiece[face]) || getColorByType('none')

    return {
      face,
      fill,
      key: face,
      opacity: '1',
      pieceIndex,
      points: this.getPoints(faces[face]),
      readOnly,
      stroke: 'black',
      strokeWidth: '0.5'
    }
  }

  getPoints (pointsMatrix) {
    return pointsMatrix.join(' ')
  }

  handleClick (e) {
    e.preventDefault()

    const { applyExtra, extras, pieceIndex, readOnly, treatmentColor } = this.props
    const { selectedExtra } = extras

    if (!readOnly) {
      if (selectedExtra) {
        const extraData = {
          piece: pieceIndex,
          extra: {
            key: extras.selectedExtra,
            color: treatmentColor
          }
        }

        applyExtra(extraData)
      }
    }
  }

  // TODO: re do this logic in a single method that accept the desired extra component to render
  renderCircle () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.C) {
      return <Circle color={getColorByType(currentPiece.extrasApplied.C)} />
    }
  }

  renderHalfRectangle () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.HR) {
      return <HalfRectangle color={getColorByType(currentPiece.extrasApplied.HR)} />
    }
  }

  renderLine () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.L) {
      return <Line color={getColorByType(currentPiece.extrasApplied.L)} />
    }
  }

  renderRectangle () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.R) {
      return <Rectangle color={getColorByType(currentPiece.extrasApplied.R)} />
    }
  }

  /* eslint-disable react/jsx-pascal-case */
  renderSE () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.SE) {
      return <SE color={getColorByType(currentPiece.extrasApplied.SE)} />
    }
  }

  renderTC () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.TC) {
      return <TC color={getColorByType(currentPiece.extrasApplied.TC)} />
    }
  }
  /* eslint-enable react/jsx-pascal-case */

  renderX () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.X) {
      return <X color={getColorByType(currentPiece.extrasApplied.X)} />
    }
  }

  renderZigzag () {
    const pieces = this.getOdonthogramData()
    const { getColorByType, pieceIndex } = this.props
    const currentPiece = pieces[pieceIndex]

    if (currentPiece && currentPiece.extrasApplied && currentPiece.extrasApplied.Z) {
      return <Zigzag color={getColorByType(currentPiece.extrasApplied.Z)} />
    }
  }

  renderPiece () {
    const polygon = []

    for (const face in faces) {
      polygon.push(<Polygon {...this.getPolygonProps(face)} />)
    }

    return polygon
  }

  renderPieceNumber () {
    return (
      <text className='Piece__number' x={5} y={-2}>{this.props.pieceIndex}</text>
    )
  }

  render () {
    return (
      <g
        transform={this.props.translateTransform}
        data-piece-index={this.props.pieceIndex}
        onClick={this.handleClick.bind(this)}
      >
        {this.renderPieceNumber()}
        {this.renderCircle()}
        {this.renderX()}
        {this.renderZigzag()}
        {this.renderHalfRectangle()}
        {this.renderRectangle()}
        {this.renderSE()}
        {this.renderTC()}
        {this.renderLine()}
        {this.renderPiece()}
      </g>
    )
  }
}

const mapDispatchToProps = dispatch => ({ applyExtra: (extraData) => dispatch(applyExtra(extraData)) })
const mapStateToProps = state => ({
  ...state,
  getColorByType: (type) => getSelectedTreatmentColorByType(state, type),
  treatmentColor: getSelectedTreatmentType(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Piece)
