export default (state = {}, action) => {
  switch (action.type) {
    case 'SELECT_EXTRA': {
      const selectedExtra = (state.selectedExtra && state.selectedExtra === action.payload) ? '' : action.payload

      return {
        ...state,
        selectedExtra
      }
    }
    default:
      return state
  }
}
