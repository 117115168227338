export default (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, { submitting: true, loginResponse: {} })
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, { loginResponse: action.payload, submitting: false })
    case 'LOGIN_FAILURE':
      return Object.assign({}, state, { loginResponse: action.payload, submitting: false })
    case 'LOGOUT':
      return Object.assign({}, state, { submitting: false, loginResponse: {} })
    default:
      return state
  }
}
