import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Pagination } from 'components/Pagination/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui/table'
import { Translations } from 'services'
import { formatDate } from 'utils/formatDate'

import './PatientsTable.css'
import { getPatients, getPatientsRequest, getTotalPatients } from 'selectors/patients'
import { getPatients as getPatientsAction } from 'actions/patients'

type Props = {
  showContextMenu: (e: unknown, patient: unknown) => void
}

export const PatientsTable: React.FC<Props> = ({ showContextMenu }) => {
  const dispatch = useDispatch()
  const [sortBy, setSortBy] = useState('createdAt')
  const [ascending, setAscending] = useState(false)
  const patients = useSelector(getPatients)
  const patientsRequest = useSelector(getPatientsRequest)
  const totalPatients = useSelector(getTotalPatients)

  const handleSort = useCallback((columnName: string) => {
    setSortBy(columnName)
    setAscending((prev) => !prev)

    dispatch(getPatientsAction({
      ...patientsRequest,
      sort: columnName,
      asc: !ascending ? 1 : -1
    }))
  }, [dispatch, patientsRequest, ascending])

  const renderSortIcon = useCallback((columnName: string) => {
    const icon = (ascending) ? <i className='angle up icon' /> : <i className='angle down icon' />

    if (columnName === sortBy) {
      return (
        <span>
          {(sortBy === columnName) ? icon : null}
        </span>
      )
    }
  }, [ascending, sortBy])

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="cursor-pointer" onClick={() => handleSort('name')}>
              <p>
                {Translations.translate('PATIENTS_TABLE.NAME')} {renderSortIcon('name')}
              </p>
            </TableHead>
            <TableHead className="cursor-pointer" onClick={() => handleSort('dateOfBirth')}>
              <p>
                {Translations.translate('PATIENTS_TABLE.DOB')} {renderSortIcon('dateOfBirth')}
              </p>
            </TableHead>
            <TableHead>
              {Translations.translate('PATIENTS_TABLE.HEALTH_INSURANCE')}
            </TableHead>
            <TableHead>
              {Translations.translate('PATIENTS_TABLE.ADDRESS')}
            </TableHead>
            <TableHead className="cursor-pointer" onClick={() => handleSort('createdAt')}>
              <p>
                {Translations.translate('PATIENTS_TABLE.CREATED_AT')} {renderSortIcon('createdAt')}
              </p>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {patients.map((patient) => {
            const {
              _id,
              address,
              age,
              createdAt,
              dateOfBirth,
              healthInsurance,
              name
            } = patient

            return (
              <TableRow key={_id} onContextMenu={(e) => showContextMenu(e, patient)}>
                <TableCell>
                  <Link to={`/patients/${_id}?tab=odonthogram`}>
                    {name}
                  </Link>
                </TableCell>
                <TableCell>{formatDate(dateOfBirth)} ({age})</TableCell>
                <TableCell>
                  {healthInsurance?.name ?? '-'} ({healthInsurance?.plan ?? '-'} | {healthInsurance?.number ?? '-'})
                </TableCell>
                <TableCell>{address?.street ?? '-'} {address?.city ?? '-'}</TableCell>
                <TableCell>{formatDate(createdAt, true)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
        {totalPatients > 15 && (
          <TableFooter>
            <TableRow>
              <TableHead colSpan={5}>
                <Pagination />
              </TableHead>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  )
}
