export const getImageById = state => {
  const selectedImageId = state.files.selectedImageId
  const selectedImage = state.files.images.filter(image => image._id === selectedImageId)

  return selectedImage[0]
}

export const getImages = state => {
  return state.files.images ?? []
}

export const getFiles = state => {
  return state.files.files ?? []
}
