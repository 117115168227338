import React from 'react'
import PropTypes from 'prop-types'
import { Translations } from 'services'
import moment from 'moment'

class PatientForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      age: ''
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState && prevState.age !== this.state.age) {
      // since age is calculated, send the input chage manually
      this.props.handleInputChange({ target: { name: 'age', value: this.state.age } })
    }
  }

  handleDateOfBirthChange (event) {
    const { handleInputChange } = this.props
    const { target: { value } } = event

    if (moment(value).isValid()) {
      const age = moment().diff(moment(value), 'years')

      if (!isNaN(age)) {
        this.setState({ age })
      }

      // send original event to set the dob
      handleInputChange(event)
    }
  }

  render () {
    const { patient = {}, type } = this.props
    const { address = {}, contact = {}, healthInsurance = {} } = patient

    if (patient.dateOfBirth) {
      patient.dateOfBirth = moment(patient.dateOfBirth).format('YYYY-MM-DD')
    }

    return (
      <div className='PatientForm'>
        <form className='ui large form' onSubmit={(e) => this.props.handleSubmit(e)}>
          <div className='ui stacked segment'>
            <div className='field'>
              <div className=''>
                <div className='two fields'>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.FULL_NAME')}</label>
                    <input
                      type='text'
                      name='name'
                      placeholder={Translations.translate('PATIENT_FORM.FULL_NAME')}
                      value={patient.name}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.ID')}</label>
                    <input
                      type='text'
                      name='identificationNumber'
                      placeholder={Translations.translate('PATIENT_FORM.ID')}
                      value={patient.identificationNumber}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='field'>
              <div className=''>
                <div className='three fields'>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.HEALTH_INSURANCE')}</label>
                    <input
                      type='text'
                      name='healthInsurance.name'
                      placeholder={Translations.translate('PATIENT_FORM.HEALTH_INSURANCE')}
                      value={healthInsurance.name}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.PLAN')}</label>
                    <input
                      type='text'
                      name='healthInsurance.plan'
                      placeholder={Translations.translate('PATIENT_FORM.PLAN')}
                      value={healthInsurance.plan}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.NUMBER')}</label>
                    <input
                      type='text'
                      name='healthInsurance.number'
                      placeholder={Translations.translate('PATIENT_FORM.NUMBER')}
                      value={healthInsurance.number}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='field'>
              <div className=''>
                <div className='three fields'>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.DOB')}</label>
                    <input
                      type='date'
                      name='dateOfBirth'
                      placeholder={Translations.translate('PATIENT_FORM.DOB')}
                      value={patient.dateOfBirth}
                      onChange={this.handleDateOfBirthChange.bind(this)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.AGE')}</label>
                    <input
                      type='number'
                      name='age'
                      placeholder={Translations.translate('PATIENT_FORM.AGE')}
                      disabled
                      value={(type === 'edit') ? patient.age : this.state.age}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.GENDER')}</label>
                    <select
                      className='ui dropdown'
                      name='gender'
                      value={patient.gender}
                      onChange={(e) => this.props.handleInputChange(e)}
                    >
                      <option value='other'>{Translations.translate('PATIENT_FORM.GENDER.OTHER')}</option>
                      <option value='female'>{Translations.translate('PATIENT_FORM.GENDER.FEMALE')}</option>
                      <option value='male'>{Translations.translate('PATIENT_FORM.GENDER.MALE')}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='field'>
              <div className=''>
                <div className='three fields'>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.ADDRESS')}</label>
                    <input
                      type='text'
                      name='address.street'
                      placeholder={Translations.translate('PATIENT_FORM.ADDRESS')}
                      value={address.street}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.CITY')}</label>
                    <input
                      type='text'
                      name='address.city'
                      placeholder={Translations.translate('PATIENT_FORM.CITY')}
                      value={address.city}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.PROVINCE')}</label>
                    <input
                      type='text'
                      name='address.province'
                      placeholder={Translations.translate('PATIENT_FORM.PROVINCE')}
                      value={address.province}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='field'>
              <div className=''>
                <div className='three fields'>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.PHONE_NUMBER')}</label>
                    <input
                      type='text'
                      name='contact.phoneNumber'
                      placeholder={Translations.translate('PATIENT_FORM.PHONE_NUMBER')}
                      value={contact.phoneNumber}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.CELLPHONE_NUMBER')}</label>
                    <input
                      type='text'
                      name='contact.cellphoneNumber'
                      placeholder={Translations.translate('PATIENT_FORM.CELLPHONE_NUMBER')}
                      value={contact.cellphoneNumber}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                  <div className='field'>
                    <label>{Translations.translate('PATIENT_FORM.EMAIL')}</label>
                    <input
                      type='email'
                      name='contact.email'
                      placeholder={Translations.translate('PATIENT_FORM.EMAIL')}
                      value={contact.email}
                      onChange={(e) => this.props.handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <button className='ui fluid large teal submit button'>
              {(type === 'edit')
                ? Translations.translate('PATIENT_FORM.UPDATE')
                : Translations.translate('PATIENT_FORM.SAVE')}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

PatientForm.propTypes = {
  handlePatientCreation: PropTypes.func,
  handleInputChange: PropTypes.func
}

export default PatientForm
