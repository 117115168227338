import { OdonthogramsService, Translations } from 'services'
import { pushNotification } from 'actions/notifications'
// import { begin, end, pendingTask } from 'react-redux-spinner'

export const applyExtra = (extraData) => dispatch => {
  dispatch({
    type: 'APPLY_EXTRA',
    payload: extraData
  })
}

const requestOdonthogram = () => dispatch => {
  dispatch({
    type: 'REQUEST_ODONTHOGRAM'
  })
}

const requestOdonthogramFailure = (err) => dispatch => {
  dispatch({
    type: 'REQUEST_ODONTHOGRAM_FAILURE',
    payload: err
  })
}

const requestOdonthogramSuccess = (odonthogram) => dispatch => {
  dispatch({
    type: 'REQUEST_ODONTHOGRAM_SUCCESS',
    payload: odonthogram
  })
}

const requestOdonthogramUpdate = () => dispatch => {
  dispatch({
    type: 'REQUEST_ODONTHOGRAM_UPDATE'
  })
}

const requestOdonthogramUpdateSuccess = (updatedOdonthogram) => dispatch => {
  dispatch({
    type: 'REQUEST_ODONTHOGRAM_UPDATE_SUCCESS',
    payload: updatedOdonthogram
  })
  dispatch(pushNotification({
    message: updatedOdonthogram.message,
    title: Translations.translate('SUCCESS'),
    type: 'success'
  }))
}

const requestOdonthogramUpdateFailure = (err) => dispatch => {
  dispatch({
    type: 'REQUEST_ODONTHOGRAM_UPDATE_FAILURE',
    payload: err
  })
  dispatch(pushNotification({
    type: 'error',
    title: Translations.translate('ERROR'),
    message: err.message || err
  }))
}

export const updateOdonthogram = (odonthogramId, odonthogramData) => dispatch => {
  dispatch(requestOdonthogramUpdate())

  OdonthogramsService.updateOdonthogram(odonthogramId, odonthogramData)
    .then(odonthogram => dispatch(requestOdonthogramUpdateSuccess(odonthogram)))
    .catch(err => dispatch(requestOdonthogramUpdateFailure(err)))
}

export const cleanOdonthogramData = () => dispatch => {
  dispatch({
    type: 'CLEAN_ODONTHOGRAM_DATA'
  })
}

export const getOdonthogram = (id) => dispatch => {
  dispatch(requestOdonthogram())

  OdonthogramsService.getById(id)
    .then(odonthogram => dispatch(requestOdonthogramSuccess(odonthogram)))
    .catch(err => dispatch(requestOdonthogramFailure(err)))
}

export const paintFace = (faceData) => dispatch => {
  dispatch({
    type: 'PAINT_FACE',
    payload: faceData
  })
}

export const setObservations = (observations) => dispatch => {
  dispatch({
    type: 'SET_OBSERVATIONS',
    payload: observations
  })
}

export const addObservation = () => dispatch => {
  dispatch({
    type: 'ADD_OBSERVATION'
    // [pendingTask]: begin
  })
}

export const addObservationSuccess = (odonthogram) => dispatch => {
  dispatch({
    type: 'ADD_OBSERVATION_SUCCESS',
    payload: odonthogram
    // [pendingTask]: end
  })
}

export const addObservationFailure = (err) => dispatch => {
  dispatch({
    type: 'ADD_OBSERVATION_FAILURE',
    payload: err
    // [pendingTask]: end
  })
}

export const addObservationToOdonthogram = (odonthogramId, observation) => dispatch => {
  dispatch(addObservation())

  OdonthogramsService.addObservation(odonthogramId, observation)
    .then(response => {
      dispatch(addObservationSuccess(response))
      dispatch(getOdonthogram(response.data._id))
    })
    .catch(err => dispatch(addObservationFailure(err)))
}

export const removeObservationFromOdothogram = (odonthogramId, observationId) => async dispatch => {
  dispatch({
    type: 'REMOVE_OBSERVATION_BEGIN'
    // [pendingTask]: begin
  })

  try {
    const updatedOdonthogram = await OdonthogramsService.removeObservation(odonthogramId, observationId)

    dispatch({
      type: 'REMOVE_OBSERVATION_SUCCESS',
      payload: updatedOdonthogram
      // [pendingTask]: end
    })
  } catch (err) {
    dispatch({
      type: 'REMOVE_OBSERVATION_FAILURE',
      payload: err
      // [pendingTask]: end
    })
    dispatch(pushNotification({
      message: err.message || err,
      title: Translations.translate('ERROR'),
      type: 'error'
    }))
  }
}
