// import { begin, end, pendingTask } from 'react-redux-spinner'
import { BaseHttpService, PatientsService, Translations } from 'services'
import { pushNotification } from 'actions/notifications'

export const ATTATCH_PATIENT_IMAGE_SUCCESS = 'ATTATCH_PATIENT_IMAGE_SUCCESS'
export const DETACH_PATIENT_IMAGE_SUCCESS = 'DETACH_PATIENT_IMAGE_SUCCESS'
export const CLEAN_STATE = 'CLEAN_STATE'
export const CLEAN_PATIENT = 'CLEAN_PATIENT'
export const CLEAN_PATIENT_IMAGES = 'CLEAN_PATIENT_IMAGES'
export const DELETE_PATIENT_BEGIN = 'DELETE_PATIENT_BEGIN'
export const DELETE_PATIENT_FAILURE = 'DELETE_PATIENT_FAILURE'
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS'
export const GET_PATIENT_BEGIN = 'GET_PATIENT_BEGIN'
export const GET_PATIENT_FAILURE = 'GET_PATIENT_FAILURE'
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS'
export const GET_PATIENT_IMAGES_BEGIN = 'GET_PATIENT_IMAGES_BEGIN'
export const GET_PATIENT_IMAGES_FAILURE = 'GET_PATIENT_IMAGES_FAILURE'
export const GET_PATIENT_IMAGES_SUCCESS = 'GET_PATIENT_IMAGES_SUCCESS'
export const GET_PATIENT_PROFILE_IMAGE_BEGIN = 'GET_PATIENT_PROFILE_IMAGE_BEGIN'
export const GET_PATIENT_PROFILE_IMAGE_FAILURE = 'GET_PATIENT_PROFILE_IMAGE_FAILURE'
export const GET_PATIENT_PROFILE_IMAGE_SUCCESS = 'GET_PATIENT_PROFILE_IMAGE_SUCCESS'
export const GET_PATIENTS_BEGIN = 'GET_PATIENTS_BEGIN'
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS'
export const GET_PATIENTS_FAILURE = 'GET_PATIENTS_FAILURE'
export const UPDATE_PATIENT_BEGIN = 'UPDATE_PATIENT_BEGIN'
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE'
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_BEGIN = 'UPLOAD_PROFILE_IMAGE_BEGIN'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE'

const API_URL = BaseHttpService.getApiV1Url()
const API_V3_URL = BaseHttpService.getApiV3Url()
const FILES_API_URL = `${BaseHttpService.getApiV3Url()}/files`

export const attatchPatientImageSuccess = (image) => dispatch => {
  dispatch({
    type: ATTATCH_PATIENT_IMAGE_SUCCESS,
    payload: {
      image
    }
  })
}

export const detachPatientImageSuccess = (fileId) => dispatch => {
  dispatch({
    type: DETACH_PATIENT_IMAGE_SUCCESS,
    payload: {
      fileId
    }
  })
}

export const cleanPatientImages = () => dispatch => {
  dispatch({
    type: CLEAN_PATIENT_IMAGES
  })
}

export const cleanPatient = () => dispatch => {
  dispatch({
    type: CLEAN_PATIENT
  })
}

export const cleanState = () => dispatch => {
  dispatch({
    type: CLEAN_STATE
  })
}

const deletePatientBegin = () => dispatch => {
  dispatch({
    type: DELETE_PATIENT_BEGIN
    // [pendingTask]: begin
  })
}

const deletePatientFailure = (error) => dispatch => {
  dispatch({
    type: DELETE_PATIENT_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

const deletePatientSuccess = (payload) => dispatch => {
  dispatch({
    type: DELETE_PATIENT_SUCCESS,
    // [pendingTask]: end,
    payload
  })
}

export const deletePatient = (patientId) => async (dispatch) => {
  dispatch(deletePatientBegin())

  try {
    const res = await PatientsService.deletePatient(patientId)

    dispatch(deletePatientSuccess(res))

    return res
  } catch (error) {
    dispatch(deletePatientFailure(error))
  }
}

export const getPatientImagesBegin = () => dispatch => {
  dispatch({
    type: GET_PATIENT_IMAGES_BEGIN
    // [pendingTask]: begin
  })
}

export const getPatientImagesFailure = (error) => dispatch => {
  dispatch({
    type: GET_PATIENT_IMAGES_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

export const getPatientImagesSuccess = (response) => dispatch => {
  dispatch({
    type: GET_PATIENT_IMAGES_SUCCESS,
    // [pendingTask]: end,
    payload: response
  })
}

export const getPatientImages = (patientId) => dispatch => {
  dispatch(getPatientImagesBegin())

  return new Promise((resolve, reject) => {
    fetch(
      `${API_V3_URL}/files/patient/${patientId}?type=image`,
      BaseHttpService.getOptions()
    )
      .then(response => response.json())
      .then(jsonResponse => {
        dispatch(getPatientImagesSuccess(jsonResponse))
        resolve(jsonResponse)
      })
      .catch(error => {
        dispatch(getPatientImagesFailure(error))
        reject(error)
      })
  })
}

export const getPatientProfileImageBegin = () => dispatch => {
  dispatch({
    type: GET_PATIENT_PROFILE_IMAGE_BEGIN
    // [pendingTask]: begin
  })
}

export const getPatientProfileImageFailure = (error) => dispatch => {
  dispatch({
    type: GET_PATIENT_PROFILE_IMAGE_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

export const getPatientProfileImageSuccess = (response) => dispatch => {
  dispatch({
    type: GET_PATIENT_PROFILE_IMAGE_SUCCESS,
    // [pendingTask]: end,
    ...(response) ? { payload: response } : {}
  })
}

export const getPatientProfileImage = (patientId) => dispatch => {
  dispatch(getPatientProfileImageBegin())

  let fileId

  return new Promise((resolve, reject) => {
    fetch(
      `${API_URL}/patients/${patientId}/profile-image`,
      BaseHttpService.getOptions()
    )
      .then(response => response.json())
      .then(response => {
        if (response && response.file) {
          fileId = response.file

          return fetch(`${FILES_API_URL}/${fileId}`, BaseHttpService.getOptions())
            .then((profileImageData) => profileImageData.blob())
            .then((file) => {
              dispatch(getPatientProfileImageSuccess({ _id: fileId, image: file }))
              resolve(file)
            })
        } else {
          dispatch(getPatientProfileImageSuccess())
          resolve()
        }
      })
      .catch(error => {
        dispatch(getPatientImagesFailure(error))
        reject(error)
      })
  })
}

export const getPatients = ({
  asc = -1,
  itemsPerPage = 15,
  nameFilter = '',
  page = 1,
  sort = 'createdAt'
}) => dispatch => {
  dispatch(getPatientsBegin({
    patientsRequest: {
      asc,
      itemsPerPage,
      nameFilter,
      page,
      sort
    }
  }))

  return new Promise((resolve, reject) => {
    let url = `${API_URL}/patients?page=${page}&itemsPerPage=${itemsPerPage}`

    if (nameFilter) {
      url += `&nameFilter=${nameFilter}`
    }

    if (sort) {
      url += `&sort=${sort}&asc=${asc}`
    }

    fetch(url, BaseHttpService.getOptions())
      .then(response => response.json())
      .then(jsonResponse => {
        if (!jsonResponse.success) {
          throw jsonResponse
        }

        dispatch(getPatientsSuccess(jsonResponse))
        resolve(jsonResponse)
      })
      .catch(err => {
        dispatch(getPatientsFailure(err))
        reject(err)
      })
  })
}

const getPatientsBegin = (requestData) => dispatch => {
  dispatch({
    type: GET_PATIENTS_BEGIN,
    // [pendingTask]: begin,
    payload: requestData
  })
}

const getPatientsSuccess = (patients) => dispatch => {
  dispatch({
    type: GET_PATIENTS_SUCCESS,
    // [pendingTask]: end,
    payload: patients
  })
}

const getPatientsFailure = (err) => dispatch => {
  dispatch({
    type: GET_PATIENTS_FAILURE,
    payload: err
    // [pendingTask]: end
  })
}

export const getPatientBegin = () => dispatch => {
  dispatch({
    type: GET_PATIENT_BEGIN
    // [pendingTask]: begin
  })
}

export const getPatientFailure = (err) => dispatch => {
  dispatch({
    type: GET_PATIENT_FAILURE,
    payload: err
    // [pendingTask]: end
  })
}

export const getPatientSuccess = (patient) => dispatch => {
  dispatch({
    type: GET_PATIENT_SUCCESS,
    payload: patient
    // [pendingTask]: end
  })
}

export const getPatient = (patientId) => dispatch => {
  dispatch(getPatientBegin())

  return new Promise((resolve, reject) => {
    fetch(
      `${API_URL}/patients/${patientId}`,
      BaseHttpService.getOptions()
    )
      .then(response => response.json())
      .then(jsonResponse => {
        dispatch(getPatientSuccess(jsonResponse.data))
        resolve(jsonResponse.data)
      })
      .catch(error => {
        dispatch(getPatientFailure(error))
        reject(error)
      })
  })
}

export const createPatient = (patientData) => dispatch => {
  dispatch({
    type: 'CREATE_PATIENT',
    payload: patientData
    // [pendingTask]: begin
  })
}

export const createPatientFailure = (createPatientResponse) => dispatch => {
  dispatch({
    type: 'CREATE_PATIENT_FAILURE',
    payload: createPatientResponse
    // [pendingTask]: end
  })
}

export const createPatientSuccess = (createPatientResponse) => dispatch => {
  dispatch({
    type: 'CREATE_PATIENT_SUCCESS',
    payload: createPatientResponse
    // [pendingTask]: end
  })
}

// posibles pages: 'information', 'treatment'
export const changePatientPage = (page) => dispatch => {
  dispatch({
    type: 'CHANGE_PATIENT_PAGE',
    payload: page
  })
}

export const changePatientDetailsTab = (tab) => dispatch => {
  dispatch({
    type: 'CHANGE_PATIENT_DETAILS_TAB',
    payload: tab
  })
}

export const changePatientsTablePage = (pageNumber) => dispatch => {
  dispatch({
    type: 'CHANGE_PATIENTS_TABLE_PAGE',
    payload: pageNumber
  })
}

export const updatePatient = (patientId, changes) => async (dispatch) => {
  dispatch(updatePatientBegin())

  try {
    const updatedPatient = await PatientsService.updatePatient(patientId, changes)

    dispatch(updatePatientSuccess(updatedPatient))

    return updatedPatient
  } catch (error) {
    dispatch(updatePatientFailure(error))
  }
}

export const updatePatientBegin = () => dispatch => {
  dispatch({
    type: UPDATE_PATIENT_BEGIN
    // [pendingTask]: begin
  })
}

export const updatePatientSuccess = (updatePatientResponse) => dispatch => {
  dispatch({
    type: UPDATE_PATIENT_SUCCESS,
    payload: updatePatientResponse
    // [pendingTask]: end
  })
}

export const updatePatientFailure = (updatePatientResponse) => dispatch => {
  dispatch({
    type: UPDATE_PATIENT_FAILURE,
    payload: updatePatientResponse
    // [pendingTask]: end
  })
}

const uploadProfileImageBegin = () => dispatch => {
  dispatch({
    type: UPLOAD_PROFILE_IMAGE_BEGIN
    // [pendingTask]: begin
  })
}

const uploadProfileImageFailure = (error) => dispatch => {
  dispatch({
    type: UPLOAD_PROFILE_IMAGE_FAILURE,
    payload: error
    // [pendingTask]: end
  })
}

const uploadProfileImageSuccess = (payload) => dispatch => {
  dispatch({
    type: UPLOAD_PROFILE_IMAGE_SUCCESS,
    payload
    // [pendingTask]: end
  })
}

export const uploadProfileImage = (fileData, patientId) => async (dispatch) => {
  const url = `${API_URL}/patients/${patientId}/profile-image`

  const options = {
    ...BaseHttpService.getOptions(),
    body: fileData,
    // headers: {
    // This generates the error Multipart: Boundary not found on the server
    // 'Content-Type': 'multipart/form-data'
    // },
    method: 'post'
  }

  // content-type is making the api not handle the file properly
  delete options.headers['Content-Type']

  dispatch(uploadProfileImageBegin())

  let imageData

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => response.json())
      .then(jsonResponse => {
        if (!jsonResponse.success) {
          throw jsonResponse
        }

        imageData = jsonResponse.data

        return fetch(`${FILES_API_URL}/${imageData.file}`, BaseHttpService.getOptions())
      })
      .then((profileImage) => profileImage.blob())
      .then((profileImageFile) => {
        dispatch(uploadProfileImageSuccess({ _id: imageData.file, image: profileImageFile }))

        dispatch(pushNotification({
          type: 'success',
          title: Translations.translate('ERROR'),
          message: Translations.translate('UPLOAD.SUCCESS_MESSAGE')
        }))

        resolve(imageData)
      })
      .catch(err => {
        dispatch(uploadProfileImageFailure(err))

        dispatch(pushNotification({
          type: 'error',
          title: Translations.translate('ERROR'),
          message: Translations.translate('UPLOAD.FAILURE_MESSAGE')
        }))

        reject(err)
      })
  })
}
