/* HOC for prevent an unauthenticated user to visit protected routes */

import React from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'

import { getHasUnauthorizedError } from 'selectors/patients'
import AuthenticationService from 'services/authentication'

function trackPage (path) {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  trackPage(rest.path)

  return (
    <Route
      {...rest} render={(props) => (
        AuthenticationService.isLoggedIn()
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )}
    />
  )
}

export const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  trackPage(rest.path)

  return (
    <Route
      {...rest} render={(props) => (
        !AuthenticationService.isLoggedIn()
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  )
}

export const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest} render={(props) => (
        AuthenticationService.isAdmin()
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  )
}

// TODO: This is only a temporal patch until some refresh token logic is implemented
export const SwitchWithInterceptor = ({ children }) => {
  const hasUnauthorizedError = useSelector(getHasUnauthorizedError)

  if (hasUnauthorizedError) {
    AuthenticationService.logout()

    window.location.reload()
  }

  return (
    <Switch>
      {children}
    </Switch>
  )
}
