import AuthenticationService from './authentication'
import BaseHttpService from './baseHttpService'

class UsersService {
  constructor () {
    this.v1Url = BaseHttpService.getApiV1Url()
  }

  create (data, isRegister) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify(data)
    }

    return new Promise((resolve, reject) => {
      let url = `${BaseHttpService.getApiV1Url()}/users`

      if (isRegister) {
        url += '/register'
      }

      fetch(url, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  getPreferences () {
    if (!AuthenticationService.isLoggedIn()) {
      return Promise.reject(new Error('Unauthorized'))
    }

    const { _id } = AuthenticationService.getUserData()
    return new Promise((resolve, reject) => {
      fetch(`${this.v1Url}/users/${_id}/preferences`, { ...BaseHttpService.getOptions() })
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  verify (email, hash) {
    return new Promise((resolve, reject) => {
      fetch(`${BaseHttpService.getApiV1Url()}/users/verify?email=${email}&hash=${hash}`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => reject(err))
    })
  }

  updatePreferences (preferences) {
    if (!AuthenticationService.isLoggedIn()) {
      return Promise.reject(new Error('Unauthorized'))
    }

    const { _id } = AuthenticationService.getUserData()
    return new Promise((resolve, reject) => {
      fetch(`${this.v1Url}/users/${_id}/preferences`, {
        ...BaseHttpService.getOptions(),
        method: 'put',
        body: JSON.stringify(preferences)
      })
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  async generateResetPassword (id) {
    const res = await fetch(
      `${this.v1Url}/users/${id}/generate-reset-password`,
      {
        ...BaseHttpService.getOptions(),
        method: 'put'
      }
    )

    return res.json()
  }

  async resetPassword (id, token, newPassword) {
    const res = await fetch(
      `${this.v1Url}/users/${id}/reset-password`,
      {
        ...BaseHttpService.getOptions(),
        method: 'post',
        body: JSON.stringify({
          token,
          newPassword
        })
      }
    )

    const json = await res.json()

    if (!res.ok) {
      throw json
    }

    return json
  }
}

export default new UsersService()
