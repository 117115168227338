import { createStore, applyMiddleware, compose } from 'redux'
import { logger } from 'redux-logger'
import rootReducer from './reducers/rootReducer'
import thunk from 'redux-thunk'

// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore (initialState = {}) {
  const middlewares = [thunk]

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
  }

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )
}
