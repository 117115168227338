import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { deleteFileV3, getImage } from 'actions/files'
import { Translations } from 'services'

import './Image.css'

class Image extends React.Component {
  componentDidMount () {
    const { getImage, imageId } = this.props

    getImage(imageId)
  }

  deleteImage (event, fileId) {
    event.stopPropagation()

    // TODO: use modal
    if (window.confirm(Translations.translate('IMAGE.DELETE_CONFIRMATION'))) {
      this.props.deleteFileV3(fileId)
    }
  }

  getImageInformation () {
    const { files, imageId, patients } = this.props
    const imageData = patients.patientImages[imageId]
    const currentImage = files.images.filter(image => image._id === imageId)

    return currentImage.length ? { ...imageData, image: currentImage[0].image } : {}
  }

  render () {
    const imageInfo = this.getImageInformation()

    if (!imageInfo) {
      return null
    }

    const {
      _id,
      createdAt,
      description,
      image,
      file: fileId
    } = imageInfo

    return (
      <div className='Image ui card'>
        <div className='content'>
          <div className='right floated meta'>
            {moment(createdAt).format('DD/MM/YYYY')}
          </div>
        </div>
        <div className='image'>
          <img src={image} alt={_id} />
        </div>
        <div className='content'>
          <div className='Image__description left floated meta'>
            {description || '-'}
          </div>
          <div className='right floated'>
            <a href='#' onClick={(event) => this.deleteImage(event, fileId)}>
              {Translations.translate('DELETE')}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

Image.propTypes = {
  imageId: PropTypes.string.isRequired
}

const mapDispatchToProps = dispatch => ({
  deleteFileV3: (imageId) => dispatch(deleteFileV3(imageId)),
  getImage: (imageId) => dispatch(getImage(imageId))
})
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(Image)
