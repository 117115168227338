export default (state = { showing: false }, action) => {
  switch (action.type) {
    case 'SHOW_CONTEXT_MENU':
      return Object.assign({}, state, { data: action.payload, showing: true })
    case 'HIDE_CONTEXT_MENU':
      return Object.assign({}, state, { showing: false })
    default:
      return state
  }
}
