import React from 'react'
import classNames from 'classnames'

export default class Rectangle extends React.Component {
  render () {
    const { color, reference } = this.props

    return (
      <svg className={classNames({ reference })}>
        <rect x='0' y='0' width='20' height='20' stroke={color} strokeWidth='1' fill='transparent' />
      </svg>
    )
  }
}
