// import { begin, end, pendingTask } from 'react-redux-spinner'
import {
  AuthenticationService,
  BaseHttpService,
  Translations,
  StorageService,
  UsersService
} from 'services'
import { pushNotification } from 'actions/notifications'

export const CHANGE_LANGUAGE_BEGIN = 'CHANGE_LANGUAGE_BEGIN'
export const CHANGE_LANGUAGE_FAILURE = 'CHANGE_LANGUAGE_FAILURE'
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS'
export const CHANGE_STATUS_BEGIN = 'CHANGE_STATUS_BEGIN'
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE'
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS'
export const GET_ALL_USERS_BEGIN = 'GET_ALL_USERS_BEGIN'
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_USER_PREFERENCES_BEGIN = 'GET_USER_PREFERENCES_BEGIN'
export const GET_USER_PREFERENCES_FAILURE = 'GET_USER_PREFERENCES_FAILURE'
export const GET_USER_PREFERENCES_SUCCESS = 'GET_USER_PREFERENCES_SUCCESS'

const API_URL = BaseHttpService.getApiV1Url()

export const createUserRequest = (userData) => dispatch => {
  dispatch({
    type: 'CREATE_USER_REQUEST',
    payload: userData
  })
}

export const createUserSuccess = (createUserResponse) => dispatch => {
  dispatch(pushNotification({
    message: createUserResponse.message,
    title: Translations.translate('SUCCESS'),
    type: 'success'
  }))

  dispatch({
    type: 'CREATE_USER_SUCCESS',
    payload: createUserResponse
  })
}

export const createUserFailure = (createUserResponse) => dispatch => {
  dispatch(pushNotification({
    message: createUserResponse.message,
    title: Translations.translate('ERROR'),
    type: 'error'
  }))

  dispatch({
    type: 'CREATE_USER_FAILURE',
    payload: createUserResponse
  })
}

export const changeLanguageBegin = () => dispatch => {
  dispatch({
    type: CHANGE_LANGUAGE_BEGIN
    // [pendingTask]: begin
  })
}

export const changeLanguageFailure = (error) => dispatch => {
  dispatch({
    type: CHANGE_LANGUAGE_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

export const changeLanguageSuccess = (response) => dispatch => {
  dispatch({
    type: CHANGE_LANGUAGE_SUCCESS,
    // [pendingTask]: end,
    payload: response
  })
}

const changeStatusBegin = () => dispatch => {
  dispatch({
    type: CHANGE_STATUS_BEGIN
    // [pendingTask]: begin
  })
}

const changeStatusFailure = (error) => dispatch => {
  dispatch({
    type: CHANGE_STATUS_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

const changeStatusSuccess = (response) => dispatch => {
  dispatch({
    type: CHANGE_STATUS_SUCCESS,
    // [pendingTask]: end,
    payload: response
  })
}

export const changeStatus = (userId, status) => async (dispatch) => {
  dispatch(changeStatusBegin())

  try {
    const options = {
      ...BaseHttpService.getOptions(),
      body: JSON.stringify({ status }),
      method: 'put'
    }
    const res = await fetch(`${API_URL}/users/${userId}/change-status`, options)
    const json = await res.json()

    if (!json.success) {
      throw new Error(json)
    }

    dispatch(changeStatusSuccess({ userId, response: json }))

    return json
  } catch (error) {
    dispatch(changeStatusFailure(error))
  }
}

export const changeLanguage = (language) => dispatch => {
  dispatch(changeLanguageBegin())

  const userId = AuthenticationService.getUserData()._id

  return new Promise((resolve, reject) => {
    const options = {
      ...BaseHttpService.getOptions(),
      body: JSON.stringify({ language }),
      method: 'put'
    }

    let response

    fetch(`${API_URL}/users/${userId}/language`, options)
      .then(response => response.json())
      .then(jsonResponse => {
        if (!jsonResponse.success) {
          throw jsonResponse
        }

        response = jsonResponse
      })
      .then(() => getUserPreferences())
      .then(() => {
        dispatch(changeLanguageSuccess(response.data))
        resolve(response)
      })
      .catch(err => {
        dispatch(changeLanguageFailure(err))

        reject(err)
      })
  })
}

export const getAllUsersBegin = () => dispatch => {
  dispatch({
    type: GET_ALL_USERS_BEGIN
    // [pendingTask]: begin
  })
}

export const getAllUsersFailure = (error) => dispatch => {
  dispatch({
    type: GET_ALL_USERS_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

export const getAllUsersSuccess = (response) => dispatch => {
  dispatch({
    type: GET_ALL_USERS_SUCCESS,
    // [pendingTask]: end,
    payload: response
  })
}

export const getUsers = () => dispatch => {
  dispatch(getAllUsersBegin())

  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/users`, BaseHttpService.getOptions())
      .then(response => response.json())
      .then(jsonResponse => {
        if (!jsonResponse.success) {
          throw jsonResponse
        }

        dispatch(getAllUsersSuccess(jsonResponse.data))

        resolve(jsonResponse)
      })
      .catch(err => {
        dispatch(getAllUsersFailure(err))

        reject(err)
      })
  })
}

export const getUserPreferencesBegin = () => dispatch => {
  dispatch({
    type: GET_USER_PREFERENCES_BEGIN
    // [pendingTask]: begin
  })
}

export const getUserPreferencesFailure = (error) => dispatch => {
  dispatch({
    type: GET_USER_PREFERENCES_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

export const getUserPreferencesSuccess = (response) => dispatch => {
  dispatch({
    type: GET_USER_PREFERENCES_SUCCESS,
    // [pendingTask]: end,
    payload: response
  })
}

export const getUserPreferences = () => dispatch => {
  dispatch(getUserPreferencesBegin())
  UsersService.getPreferences()
    .then(preferences => {
      StorageService.persist('user-language', preferences.data.language)

      dispatch(getUserPreferencesSuccess(preferences))
    })
    .catch(err => dispatch(getUserPreferencesFailure(err)))
}
