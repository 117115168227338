import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'

import BaseView from 'components/BaseView/BaseView'
import FileUploader from 'components/FileUploader/FileUploader'
import Image from 'components/Image/Image'
import Observations from 'components/Observations/Observations'
import Odontogram from 'components/Odontogram/Odontogram'
import ProfileCard from 'components/ProfileCard/ProfileCard'

import {
  addObservationToOdonthogram,
  cleanOdonthogramData,
  getOdonthogram,
  removeObservationFromOdothogram
} from 'actions/pieces'
import {
  cleanPatient,
  cleanPatientImages,
  getPatient as getPatientAction,
  getPatientImages as getPatientImagesAction,
  getPatientProfileImage
} from 'actions/patients'
import { toggleLoading } from 'actions/loading'
import { showModal } from 'actions/modal'
import { getUserPreferences } from 'actions/users'
import {
  cleanFiles,
  selectImage
} from 'actions/files'

import { getLoading } from 'selectors/loading'
import {
  getPatient,
  getPatientImages
} from 'selectors/patients'
import { getPieces } from 'selectors/pieces'

import { Translations } from 'services'
import { formatDate } from 'utils/formatDate'

import { FilesTab } from './FilesTab'
import './PatientDetail.css'
import { getImages } from 'selectors/files'

enum Tab {
  Files = 'files',
  HealthInsurance = 'healthInsurance',
  Images = 'images',
  Odonthogram = 'odonthogram'
}

type Params = {
  id: string
}

export default function PatientDetail (): JSX.Element {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { id: patientId } = useParams<Params>()
  const queryParams = new URLSearchParams(location.search)
  const tab = (queryParams.get('tab') as Tab)

  const patient = useSelector(getPatient)
  const patientImages = useSelector(getPatientImages)
  const pieces = useSelector(getPieces)
  const loading = useSelector(getLoading)
  const files = useSelector(getImages)

  useEffect(() => {
    void (async () => {
      try {
        dispatch(toggleLoading(true))

        await Promise.all([
          dispatch(getPatientAction(patientId)),
          dispatch(getPatientImagesAction(patientId)),
          dispatch(getPatientProfileImage(patientId)),
          dispatch(getUserPreferences())
        ]).then(([patientData]) => {
          // @ts-expect-error return value is not properly typed
          if (patientData?.odonthograms?.length > 0) {
            // @ts-expect-error return value is not properly typed
            dispatch(getOdonthogram(patientData.odonthograms[0]))
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(toggleLoading(false))
      }
    })()
  }, [dispatch, patientId])

  useEffect(() => {
    return () => {
      dispatch(cleanFiles())
      dispatch(cleanOdonthogramData())
      dispatch(cleanPatient())
      dispatch(cleanPatientImages())
    }
  }, [dispatch])

  const handleTabChange = (tab: Tab): void => {
    history.push({
      pathname: location.pathname,
      search: `?tab=${tab}`
    })
  }

  const renderHealthInsuranceTab = (): JSX.Element | null => {
    const tabClass = classNames({
      'ui bottom attached tab segment active': true,
      loading: loading.loading
    })
    let tab: JSX.Element | null = null

    if (patient != null) {
      const { healthInsurance } = patient

      tab = (
        <div className={tabClass}>
          <ul>
            <li>Health insurance provider: {healthInsurance?.name ?? '-'}</li>
            <li>Number: {healthInsurance?.number ?? '-'}</li>
            <li>Plan: {healthInsurance?.plan ?? '-'}</li>
          </ul>
        </div>
      )
    }

    return tab
  }

  const showImageInModal = ({ _id, image }: { _id: string, image: string }): void => {
    dispatch(selectImage(_id))
    dispatch(showModal({
      isImage: true,
      imgSrc: image
    }))
  }

  const renderImagesTab = (): JSX.Element => {
    const tabClass = classNames('ui bottom attached tab segment active', {
      loading: loading.loading
    })

    return (
      <div className={tabClass}>
        <div className='file-uploader-wrapper'>
          <FileUploader types={['image/*']} patientId={patientId} />
        </div>
        <div className='images-wrapper'>
          {Object.keys(patientImages).map((imageId: string, key: number) => (
            <div key={key} onClick={() => showImageInModal(files.find((file) => file._id === imageId))}>
              <Image imageId={imageId} />
            </div>
          ))}
        </div>
      </div>
    )
  }

  const handleAddObservation = (odonthogram, observation): void => {
    dispatch(addObservationToOdonthogram(odonthogram, observation))
  }

  const handleRemoveObservation = (odonthogramId, observationId): void => {
    dispatch(removeObservationFromOdothogram(odonthogramId, observationId))
  }

  const renderObservations = (odonthogram): JSX.Element | null => {
    let _observations: JSX.Element | null = null

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (odonthogram?.observations) {
      const { _id, observations } = odonthogram

      _observations = (
        <Observations
          handleAddObservation={handleAddObservation}
          handleRemoveObservation={handleRemoveObservation}
          observations={observations}
          odonthogramId={_id}
        />
      )
    }

    return _observations
  }

  const renderOdonthogramTab = (): JSX.Element | null => {
    const { loading: isLoading } = loading
    let tab: JSX.Element | null = null

    if ((Boolean(patient)) && (Boolean(pieces.odonthogram))) {
      const odonthogram = pieces.odonthogram.data
      const tabClass = classNames({
        'ui bottom attached tab segment active': true,
        loading: isLoading
      })
      let odonthogramCreatedAt = '-'
      let odonthogramId = '-'

      if (odonthogram != null) {
        odonthogramCreatedAt = formatDate(odonthogram.createdAt)
        odonthogramId = odonthogram._id
      }

      if (patient != null) {
        tab = (
          <div className={tabClass}>
            <Odontogram
              date={odonthogramCreatedAt}
              id={odonthogramId}
              page='patientDetail'
              patient={patient._id}
              readOnly
            />
            {renderObservations(odonthogram)}
          </div>
        )
      }
    }

    return tab
  }

  const renderFilesTab = (): JSX.Element => {
    return (
      <FilesTab
        patientId={patientId}
      />
    )
  }

  const renderTab = (tab: Tab): JSX.Element | null | undefined => {
    const tabsMap = new Map<Tab, JSX.Element | null>([
      [Tab.Files, renderFilesTab()],
      [Tab.HealthInsurance, renderHealthInsuranceTab()],
      [Tab.Images, renderImagesTab()],
      [Tab.Odonthogram, renderOdonthogramTab()]
    ])

    return tabsMap.get(tab)
  }

  return (
    <BaseView title={Translations.translate('PATIENT.PATIENT_DETAIL')}>
      <div className='ui grid'>
        <div className='four wide column'>
          {patient != null && (
            <>
              <ProfileCard />
              <div className='PatientDetail__edit-button-wrapper'>
                <button
                className='ui primary basic button' onClick={() => {
                  history.push(`/patients/${patient._id}/edit-info`)
                }}
              >
                {Translations.translate('PATIENT.EDIT_PATIENT')}
              </button>
            </div>
          </>
          )}
        </div>
        <div className='twelve wide column'>
          <div className='ui top attached tabular menu'>
            <div
              className={tab === Tab.Odonthogram ? 'active item' : 'item'}
              onClick={() => handleTabChange(Tab.Odonthogram)}
            >
              {Translations.translate('PATIENT.ODONTOGRAM')}
            </div>
            <div
              className={tab === Tab.HealthInsurance ? 'active item' : 'item'}
              onClick={() => handleTabChange(Tab.HealthInsurance)}
            >
              {Translations.translate('PATIENT.HEALTH_INSURANCE')}
            </div>
            <div
              className={tab === Tab.Images ? 'active item' : 'item'}
              onClick={() => handleTabChange(Tab.Images)}
            >
              {Translations.translate('PATIENT.IMAGES')}
            </div>
            <div
              className={tab === Tab.Files ? 'active item' : 'item'}
              onClick={() => handleTabChange(Tab.Files)}
            >
              {Translations.translate('PATIENT.FILES')}
            </div>
          </div>
          {renderTab(tab)}
        </div>
      </div>
    </BaseView>
  )
}
