import React from 'react'
import { Helmet } from 'react-helmet'

import Sidebar from 'components/Sidebar/Sidebar'
import './BaseView.css'

type Props = {
  title: string
  withSidebar?: boolean
  children: JSX.Element
}

export default function BaseView ({
  title,
  withSidebar = true,
  children
}: Props): JSX.Element {
  return (
    <div className='BaseView'>
      <Helmet title={`Be Pacient | ${title}`} />
      {withSidebar && <Sidebar />}
      <div className='ui container pusher BaseView__content'>
        <h2 className='ui dividing header'>{title}</h2>
        {children}
      </div>
    </div>
  )
}
