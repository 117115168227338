import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { jsPDF } from 'jspdf'
import 'svg2pdf.js'

import { getPatient } from 'selectors/patients'
import { getObservations } from 'selectors/pieces'
import { formatDate } from 'utils/formatDate'

type GenerateFn = (_: Element) => void

export function usePatientPDF (): { generate: GenerateFn } {
  const patient = useSelector(getPatient)
  const observations = useSelector(getObservations)

  const generate = useCallback((odontogram: Element) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      unit: 'px'
    })

    if (patient != null) {
      const {
        address,
        age,
        contact,
        dateOfBirth,
        identificationNumber,
        name
      } = patient
      const {
        city,
        province,
        street
      } = address
      const {
        cellphoneNumber,
        email,
        phoneNumber
      } = contact

      doc.text(`Nombre: ${name}`, 10, 20)
      doc.text(
`Documento: ${identificationNumber}\
 Fecha de nacimiento: ${formatDate(dateOfBirth)}\
 Edad: ${age}`,
10,
40
      )
      doc.text(`Domicilio: ${street}`, 10, 60)
      doc.text(
        `Localidad: ${city} ${province}`,
        10,
        80
      )
      doc.text(
`Telefono: ${phoneNumber}\
 Celular: ${cellphoneNumber}\
 Email: ${email}`,
10,
100
      )
      doc.text('Odontologo: TODO Matricula: TODO', 10, 120)
      doc.text(
        // eslint-disable-next-line @typescript-eslint/quotes
`Domicilio de atencion: TODO\
 Localidad: TODO\
 Telefono: TODO`,
10,
140
      )

      odontogram.setAttribute('transform', 'scale(0.6)')
      void doc.svg(odontogram, {
        x: 35,
        y: 180
      }).then(() => {
        // doc.save('test.pdf')
        doc.output('dataurlnewwindow')
      })

      doc.text('Observaciones', 10, 340)

      // TODO: a very long observation will overflow the page
      let y = 340
      observations.forEach(({ observation, createdAt }) => {
        y += 20

        doc.text(`${formatDate(createdAt)} - ${observation}`, 10, y)
      })
    }
  }, [observations, patient])

  return {
    generate
  }
}
