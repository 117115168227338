import React from 'react'
import { Translations } from 'services'
import './Steps.css'

class Steps extends React.Component {
  render () {
    const { currentPage } = this.props

    return (
      <div className='Steps'>
        <div className='ui steps'>
          <div className={'step ' + (currentPage === 'information' ? 'active' : '')}>
            <i className='user icon' />
            <div className='content'>
              <div className='title'>{Translations.translate('STEPS.PATIENT_INFORMATION')}</div>
              <div className='description'>{Translations.translate('STEPS.BASIC_AND_CONTACT')}</div>
            </div>
          </div>
          <div className={'step ' + (currentPage === 'treatment' ? 'active' : '')}>
            <i className='plus icon' />
            <div className='content'>
              <div className='title'>{Translations.translate('STEPS.ODONTOGRAM')}</div>
              <div className='description'>{Translations.translate('STEPS.ADD_ODONTOGRAM')}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Steps
