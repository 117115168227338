export default (state = { loading: false }, action) => {
  switch (action.type) {
    case 'TOGGLE_LOADING':
      return {
        ...state,
        loading: action.payload
      }

    default:
      return state
  }
}
