import React from 'react'
import ReactGA from 'react-ga'
// import { Spinner } from 'react-redux-spinner'
import AppRouter from 'components/Router/Router'
import ContextMenu from 'components/ContextMenu/ContextMenu'
import Modal from 'components/Modal/Modal'
import { Notifications } from 'components/Notifications'
// import './App.css' // TODO: delete file
// import 'react-redux-spinner/dist/react-redux-spinner.css'

// TODO: make configurable options
ReactGA.initialize('UA-145258658-1', {})

const App: React.FC = () => {
  return (
    <div className='h-screen'>
      {/* <Spinner /> */}
      <Notifications />
      <ContextMenu />
      <AppRouter />
      <Modal />
    </div>
  )
}

export default App
