import React, { useEffect, useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeStatus, getUsers } from 'actions/users'
import BaseView from 'components/BaseView/BaseView'
import { getUsers as getUsersSelector } from 'selectors/users'
import { AdminService, UsersService } from 'services'
import { OldProfileImage } from 'services/admin'

type State = {
  features: Record<string, boolean>
  featuresLoading: boolean
  patientsLoading: boolean
  profileImages: OldProfileImage[]
  images: OldProfileImage[]
}

type Action = {
  type: 'toggleFeaturesLoading'
  | 'togglePatientsLoading'
  | 'receiveFeatures'
  | 'receivePatientStatus'
  | 'receiveProfileImages'
  | 'receiveImages'
  payload?: any
}

const initialState: State = Object.freeze({
  features: {},
  featuresLoading: false,
  patientsLoading: false,
  profileImages: [],
  images: []
})

function reducer (state: State, action: Action): State {
  switch (action.type) {
    case 'toggleFeaturesLoading': {
      return {
        ...state,
        featuresLoading: !state.featuresLoading
      }
    }
    case 'togglePatientsLoading': {
      return {
        ...state,
        patientsLoading: !state.patientsLoading
      }
    }
    case 'receiveFeatures': {
      return {
        ...state,
        featuresLoading: false,
        features: action.payload
      }
    }
    case 'receiveProfileImages': {
      return {
        ...state,
        profileImages: action.payload
      }
    }
    case 'receiveImages': {
      return {
        ...state,
        images: action.payload
      }
    }
    default:
      return state
  }
}

function Admin (): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [textFilter, setTextFilter] = useState<string>()
  const rrDispatch = useDispatch()
  const users = useSelector(getUsersSelector)
  const filteredUsers =
    (users ?? []).filter((user) => {
      if (textFilter !== undefined && textFilter.length > 0) {
        return user.email.includes(textFilter) || user.name.toLowerCase().includes(textFilter)
      }

      return user
    })

  const handleChangeStatusClick = (
    userId: string,
    status: boolean
  ) => async (): Promise<void> => {
    dispatch({ type: 'togglePatientsLoading' })
    await rrDispatch(changeStatus(userId, status))
    dispatch({ type: 'togglePatientsLoading' })
  }

  const handleResetPasswordClick = (userId: string) => async (): Promise<void> => {
    dispatch({ type: 'togglePatientsLoading' })
    await UsersService.generateResetPassword(userId)
    dispatch({ type: 'togglePatientsLoading' })
  }

  const migrateImage = async (image: OldProfileImage, type: 'profile' | 'image'): Promise<void> => {
    try {
      const response = await AdminService.migrateProfileImage(image, type)
      console.log('>>> response', response)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFeatureStatusChange = (
    feature: string,
    value: boolean
  ) => async (): Promise<void> => {
    try {
      dispatch({ type: 'toggleFeaturesLoading' })

      const _features = await AdminService.setFeature({
        ...state.features,
        [feature]: value
      })

      dispatch({ type: 'receiveFeatures', payload: _features })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'toggleFeaturesLoading' })
    }
  }

  useEffect(() => {
    void (async () => {
      dispatch({ type: 'togglePatientsLoading' })
      await rrDispatch(getUsers())
      dispatch({ type: 'togglePatientsLoading' })
    })()
  }, [rrDispatch])

  useEffect(() => {
    void (async () => {
      try {
        dispatch({ type: 'toggleFeaturesLoading' })

        const _features = await AdminService.getFeatures()

        dispatch({
          type: 'receiveFeatures',
          payload: _features
        })
      } catch (error) {
        console.error(error)
        dispatch({ type: 'toggleFeaturesLoading' })
      }
    })()
  }, [])

  useEffect(() => {
    void (async () => {
      const profileImages = await AdminService.getProfileImages()

      dispatch({ type: 'receiveProfileImages', payload: profileImages })
    })()
  }, [])

  useEffect(() => {
    void (async () => {
      const profileImages = await AdminService.getImages()

      dispatch({ type: 'receiveImages', payload: profileImages })
    })()
  }, [])

  return (
    <BaseView title="Admin" >
      <>
        <div>
          <label htmlFor="user-text">
            {'Filter by name or email: '}
          </label>
          <input
            type="text"
            onChange={(event) => {
              setTextFilter(event.currentTarget.value)
            }}
          />
        </div>
        <table>
          {(filteredUsers ?? []).map(({ _id, email, name, status, role }) => {
            return (
              <tr key={_id}>
                <td>{_id}</td>
                <td>{email}</td>
                <td>{name}</td>
                <td>{status ? 'active' : 'not active'}</td>
                <td>{role}</td>
                <td>
                  <button
                    disabled={state.patientsLoading}
                    onClick={handleChangeStatusClick(_id, !status)}
                  >
                    {status ? 'Disable' : 'Enable'}
                  </button>
                </td>
                <td>
                  <button
                    disabled={state.patientsLoading}
                    onClick={handleResetPasswordClick(_id)}
                  >
                    {'Reset pass'}
                  </button>
                </td>
              </tr>
            )
          })}
        </table>
        <br />
        <p>Feature flags</p>
        <table>
          {(Object.keys(state.features) ?? []).map((feature) => {
            return (
              <tr key={feature}>
                <td>{feature}</td>
                <td>{state.features[feature]}</td>
                <td>
                  <button
                    disabled={state.featuresLoading}
                    onClick={handleFeatureStatusChange(feature, !state.features[feature])}
                  >
                    {state.features[feature] ? 'Disable' : 'Enable'}
                  </button>
                </td>
              </tr>
            )
          })}
        </table>
        <br />
        <p>Profile images</p>
        <table>
          {state.profileImages.map((image) => {
            return (
              <tr key={image._id}>
                <td>{image._id}</td>
                <td>{image.name}</td>
                <td>{image.type}</td>
                <td>{image.active}</td>
                <td>{image.patient}</td>
                <td>{image.createdAt.toString()}</td>
                <td>{image.updatedAt.toString()}</td>
                <td>{image.uploadedBy}</td>
                <td>
                  <img width='60' height='60' src={image.base64Image} alt="" />
                </td>
                <td><button onClick={async () => await migrateImage(image, 'profile')}>Migrate</button></td>
              </tr>
            )
          })}
        </table>
        <br />
        <p>Images</p>
        <table>
          {state.images.map((image) => {
            return (
              <tr key={image._id}>
                <td>{image._id}</td>
                <td>{image.name}</td>
                <td>{image.type}</td>
                <td>{image.active}</td>
                <td>{image.patient}</td>
                <td>{image.createdAt.toString()}</td>
                <td>{image.updatedAt.toString()}</td>
                <td>{image.uploadedBy}</td>
                <td>
                  <img width='60' height='60' src={image.base64Image} alt="" />
                </td>
                <td><button onClick={async () => await migrateImage(image, 'image')}>Migrate</button></td>
              </tr>
            )
          })}
        </table>
      </>
    </BaseView>
  )
}

export default Admin
