import {
  DETACH_PATIENT_IMAGE_SUCCESS,
  CLEAN_PATIENT,
  CLEAN_PATIENT_IMAGES,
  CLEAN_STATE,
  DELETE_PATIENT_BEGIN,
  DELETE_PATIENT_FAILURE,
  DELETE_PATIENT_SUCCESS,
  GET_PATIENT_BEGIN,
  GET_PATIENT_FAILURE,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_IMAGES_BEGIN,
  GET_PATIENT_IMAGES_FAILURE,
  GET_PATIENT_IMAGES_SUCCESS,
  GET_PATIENT_PROFILE_IMAGE_BEGIN,
  GET_PATIENT_PROFILE_IMAGE_FAILURE,
  GET_PATIENT_PROFILE_IMAGE_SUCCESS,
  GET_PATIENTS_BEGIN,
  GET_PATIENTS_FAILURE,
  GET_PATIENTS_SUCCESS,
  UPDATE_PATIENT_FAILURE,
  UPDATE_PATIENT_BEGIN,
  UPDATE_PATIENT_SUCCESS,
  UPLOAD_PROFILE_IMAGE_BEGIN,
  UPLOAD_PROFILE_IMAGE_FAILURE,
  UPLOAD_PROFILE_IMAGE_SUCCESS
} from 'actions/patients'

const initialState = Object.freeze({
  error: null,
  patient: {},
  patientPage: 'information',
  patientDetailsTab: 'odonthogram',
  patientsTablePage: 1,
  patients: [],
  patientImages: [],
  patientsRequest: {}
})

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ATTATCH_PATIENT_IMAGE_SUCCESS':
      return {
        ...state,
        patientImages: {
          ...state.patientImages,
          [action.payload.image.file]: action.payload.image
        }
      }

    case DETACH_PATIENT_IMAGE_SUCCESS: {
      const _state = { ...state }
      delete _state.patientImages[action.payload.fileId]

      return _state
    }

    case CLEAN_PATIENT:
      return {
        ...state,
        patient: initialState.patient
      }

    case CLEAN_STATE:
      return {
        ...state,
        ...initialState
      }

    case 'CHANGE_PATIENT_DETAILS_TAB': {
      return Object.assign({}, state, { patientDetailsTab: action.payload })
    }

    case 'CHANGE_PATIENT_PAGE': {
      return Object.assign({}, state, { patientPage: action.payload })
    }

    case 'CHANGE_PATIENTS_TABLE_PAGE': {
      return Object.assign({}, state, { patientsTablePage: action.payload })
    }

    case CLEAN_PATIENT_IMAGES:
      return {
        ...state,
        patientImages: []
      }

    case DELETE_PATIENT_BEGIN:
    case GET_PATIENT_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      }

    case DELETE_PATIENT_FAILURE:
    case GET_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false
      }

    case GET_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        patient: {
          ...state.patient,
          ...action.payload
        }
      }

    case 'GET_PATIENTS':
    case UPDATE_PATIENT_BEGIN: {
      return {
        ...state,
        loading: true
      }
    }

    case 'GET_PATIENTS_FAILURE':
    case UPDATE_PATIENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    }

    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        patient: action.payload.data
      }

    case GET_PATIENT_IMAGES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        patientImages: []
      }

    case GET_PATIENT_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case GET_PATIENT_IMAGES_SUCCESS: {
      console.log('>>> action.payload', action.payload)

      return {
        ...state,
        loading: false,
        patientImages: action.payload.data.reduce((acum, image) => {
          // NOTE: use `file` as the key of the map to be able to request the
          // blobs using that id
          acum[image.file] = image

          return acum
        }, {})
      }
    }

    case UPLOAD_PROFILE_IMAGE_BEGIN:
    case GET_PATIENT_PROFILE_IMAGE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }

    case UPLOAD_PROFILE_IMAGE_FAILURE:
    case GET_PATIENT_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case UPLOAD_PROFILE_IMAGE_SUCCESS:
    case GET_PATIENT_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        patient: {
          ...state.patient,
          ...(action.payload)
            ? {
                profileImage: {
                  _id: action.payload._id,
                  image: URL.createObjectURL(action.payload.image)
                }
              }
            : {}
        }
      }

    case GET_PATIENTS_BEGIN:
      return {
        ...state,
        patientsRequest: action.payload.patientsRequest,
        loading: true
      }

    case GET_PATIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        patients: action.payload.data,
        totalPatients: action.payload.total
      }

    default:
      return state
  }
}
