import React from 'react'
import PropTypes from 'prop-types'
import { Translations } from 'services'
import { formatDate } from 'utils/formatDate'

import './Observations.css'

class Observations extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showInput: false,
      inputValue: ''
    }
  }

  handleInputChange (e) {
    this.setState({
      inputValue: e.target.value
    })
  }

  handleAddObservation () {
    const { odonthogramId, handleAddObservation } = this.props

    if (this.state.inputValue) {
      handleAddObservation(odonthogramId, { observation: this.state.inputValue, createdAt: Date.now() })
    }
    this.hideObservationInput()
  }

  handleClick () {
    this.setState({
      showInput: true
    })
  }

  handleRemoveObservation (observationId) {
    const { handleRemoveObservation: removeObservation, odonthogramId } = this.props

    removeObservation(odonthogramId, observationId)
  }

  hideObservationInput () {
    this.setState({ showInput: false })
  }

  renderInput () {
    return (
      <div className='Observation__observation-input ui input'>
        <input
          autoFocus
          type='text'
          placeholder={Translations.translate('OBSERVATIONS.OBSERVATION')}
          onChange={(e) => this.handleInputChange(e)}
        />
        <button
          className='Observations__add-observation-button ui positive basic button mini'
          onClick={() => this.handleAddObservation()}
        >
          {Translations.translate('OBSERVATIONS.ADD')}
        </button>
        <button
          className='Observations__add-observation-button ui negative basic button mini'
          onClick={() => this.hideObservationInput()}
        >
          {Translations.translate('OBSERVATIONS.CANCEL')}
        </button>
      </div>
    )
  }

  renderObservation (observation, index) {
    return (
      <tr key={index}>
        <td colSpan={6}>{observation.observation}</td>
        <td colSpan={2} className='right aligned'>{formatDate(observation.createdAt, true)}</td>
        <td colSpan={1} className='right aligned'>
          <a onClick={() => this.handleRemoveObservation(observation._id)}>
            <i className='trash icon' />
          </a>
        </td>
      </tr>
    )
  }

  renderAddObservation () {
    let button = null

    if (!this.state.showInput) {
      button = (
        <div className='Observations_add-observation-wrapper'>
          <button
            className='Observations__add-observation-button ui positive basic button mini'
            onClick={() => this.handleClick()}
          >
            {Translations.translate('OBSERVATIONS.ADD_OBSERVATION')}
          </button>
        </div>
      )
    }

    return button
  }

  renderObservations () {
    const { observations } = this.props
    // TODO: i18n
    let _observations = (
      <div className='Observations__no-observations-wrapper'>
        <p>{Translations.translate('OBSERVATIONS.NO_OBSERVATIONS')}</p>
      </div>
    )

    if (observations && observations.length) {
      _observations = observations.map((observation, index) => {
        return this.renderObservation(observation, index)
      })
    }

    return _observations
  }

  render () {
    return (
      <div className='Observations'>
        <div className='ui divider' />
        <div className='ui feed'>
          <div className='event'>
            <div className='Observations__content content'>
              <div className='Observations__content-text'>
                <div className='Observations__content-list'>
                  <table className='ui fixed table'>
                    <thead>
                      <tr>
                        <th colSpan={6}>
                          {Translations.translate('OBSERVATIONS.OBSERVATIONS')}
                        </th>
                        <th className='right aligned' colSpan={3}>
                          {this.renderAddObservation()}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderObservations()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {this.state.showInput && this.renderInput()}
        </div>
      </div>
    )
  }
}

Observations.propTypes = {
  handleAddObservation: PropTypes.func.isRequired,
  handleRemoveObservation: PropTypes.func.isRequired,
  odonthogramId: PropTypes.string,
  observations: PropTypes.array
}

export default Observations
