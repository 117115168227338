import StorageService from './storage'

export const Translations = {
  translate: (key) => {
    const userLanguage = StorageService.read('user-language')
      ? StorageService.read('user-language').toLowerCase()
      : 'en'

    const language = require(`../translations/${userLanguage}.json`)

    return language[key]
  }
}
