import React from 'react'
import PropTypes from 'prop-types'
import Extras from 'components/Extras/Extras'
import { Translations as t } from 'services'
import Odontogram from 'components/Odontogram/Odontogram'
import TreatmentTypeSelector from 'components/TreatmentTypeSelector/TreatmentTypeSelector'
import { cleanOdonthogramData } from 'actions/pieces'
import { getUserPreferences } from 'actions/users'
import { connect } from 'react-redux'

class TreatmentForm extends React.Component {
  componentDidMount () {
    const { cleanOdonthogramData, getUserPreferences } = this.props
    cleanOdonthogramData()
    getUserPreferences()
  }

  render () {
    const { extras, handleSubmit, page } = this.props

    return (
      <div className='TreatmentForm'>
        <div className='ui grid'>
          <div className='four wide column'>
            <TreatmentTypeSelector />
            <Extras selectedExtra={extras.selectedExtra} />
          </div>
          <div className='twelve wide column'>
            <Odontogram page={page} />
          </div>
        </div>
        <div className='ui grid'>
          <div className='four wide column'>
            <div className='button--wrapper'>
              <button
                className='ui fluid teal submit button'
                onClick={(e) => handleSubmit(e)}
              >
                {t.translate('ODONTOGRAM.SAVE_TREATMENT')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TreatmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired
}

const mapDispatchToProps = dispatch => ({
  cleanOdonthogramData: () => dispatch(cleanOdonthogramData()),
  getUserPreferences: () => dispatch(getUserPreferences())
})
const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentForm)
