import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { deleteFileV3, getFile, getPatientFilesV3 } from 'actions/files'
import { FileUploader } from 'components'
import { getFiles } from 'selectors/files'
import { Translations } from 'services'

type File = {
  _id: string
  createdAt: Date
  description: string
  file: string
  name: string
  originalName: string
  patient: string
  updatedAt: Date
  uploadedBy: string
}

type Props = {
  patientId: string
}

export function FilesTab ({ patientId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const files = useSelector(getFiles)

  useEffect(() => {
    dispatch(getPatientFilesV3(patientId))
  }, [dispatch, patientId])

  const handleLoadFile = useCallback((file: File) => {
    dispatch(getFile(file.file))
  }, [dispatch])

  const handleDeleteFile = useCallback((file: File) => {
    dispatch(deleteFileV3(file.file, 'file'))
  }, [dispatch])

  return (
    <div className='ui bottom attached tab segment active'>
      <div className='file-uploader-wrapper'>
        <FileUploader
          types={['application/pdf']}
          patientId={patientId}
          type='file'
        />
      </div>
      <div>
        {files.length > 0 && (
          <table className='ui very basic table'>
            <thead>
              <tr>
                <th>{Translations.translate('PATIENT.FILES.DESCRIPTION')}</th>
                <th>{Translations.translate('PATIENT.FILES.FILE')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {files.map((file) => {
                return (
                  <tr key={file._id}>
                    <td>
                      {file.description ?? '-'}
                    </td>
                    <td>
                      <a href='#' role='button' onClick={() => handleLoadFile(file)}>
                        {file.originalName ?? file.name}
                      </a>
                    </td>
                    <td>
                      <a href='#' onClick={() => handleDeleteFile(file)}>
                        <i className='trash alternate icon' />
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
