type State = {
  error: string | null
  submitting: boolean
  user: User
  users: User[] | null
  createUserResponse: {
    success: boolean
    message: string
  }
}

type Preferences = {
  treatments: {
    existing: string
    pending: string
  }
}

type User = {
  __v: number
  _id: string
  createdAt: Date
  email: string
  language: string
  name: string
  preferences: Preferences
  role: 'user' | 'admin'
  status: boolean
  updatedAt: Date
}

export const getUserLanguage = (state): State['user']['language'] => {
  return state.users.user.language
}

export const getUserPreferences = (state): State['user']['preferences'] => state?.users?.user?.preferences ?? {}

export const getUserOdontogramColors = (state): Preferences['treatments'] => {
  const userPreferences = getUserPreferences(state)
  return userPreferences?.treatments ?? {}
}

export const getUsers = (state): State['users'] => {
  return state.users.users
}

export const getActiveUsers = (state): State['users'] => {
  return state.users.users.filter(user => user.status)
}

export const getUnactiveUsers = (state): State['users'] => {
  return state.users.users.filter((user: User) => !user.status)
}

export const getCreateUserResponse = (state): State['createUserResponse'] => {
  return state.users.createUserResponse
}
