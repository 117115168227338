import {
  CHANGE_LANGUAGE_BEGIN,
  CHANGE_LANGUAGE_FAILURE,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_STATUS_BEGIN,
  CHANGE_STATUS_FAILURE,
  CHANGE_STATUS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_SUCCESS,
  GET_USER_PREFERENCES_BEGIN,
  GET_USER_PREFERENCES_FAILURE,
  GET_USER_PREFERENCES_SUCCESS
} from '../actions/users'

const initialState = {
  error: null,
  submitting: false,
  user: {},
  users: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PREFERENCES_BEGIN:
    case CHANGE_LANGUAGE_BEGIN:
    case CHANGE_STATUS_BEGIN:
    case 'CREATE_USER_REQUEST':
      return Object.assign({}, state, { submitting: true, createUserResponse: {} })
    case 'CREATE_USER_SUCCESS':
    case 'CREATE_USER_FAILURE':
      return Object.assign({}, state, { createUserResponse: action.payload, submitting: false })
    case GET_USER_PREFERENCES_FAILURE:
    case CHANGE_STATUS_FAILURE:
    case CHANGE_LANGUAGE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        error: action.payload,
        submitting: false
      })
    case CHANGE_LANGUAGE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: {
          ...state.user,
          language: action.payload.userLanguage
        },
        error: null,
        submitting: false
      })
    case CHANGE_STATUS_SUCCESS: {
      const updatedState = state.users
      const updatedUserIndex = state.users.findIndex(
        (user) => user._id === action.payload.userId
      )
      updatedState[updatedUserIndex].status = !updatedState[updatedUserIndex].status

      return {
        ...state,
        submitting: false,
        users: [...updatedState]
      }
    }
    case GET_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        user: {
          ...state.user,
          preferences: action.payload.data
        }
      }
    case GET_ALL_USERS_FAILURE:
      return Object.assign({}, state, { error: action.payload })
    case GET_ALL_USERS_SUCCESS:
      return Object.assign({}, state, { users: action.payload })
    default:
      return state
  }
}
