import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { pushNotification } from 'actions/notifications'
import BaseView from 'components/BaseView/BaseView'
import PatientForm from 'components/PatientForm/PatientForm'
import {
  cleanPatient,
  getPatient,
  updatePatient as updatePatientAction
} from 'actions/patients'
import {
  getLoading,
  getPatient as getPatientSelector
} from 'selectors/patients'
import { Translations } from 'services'
import Utils from 'services/utils'
import { Patient } from 'types/patient'

export default function EditPatientInfo (): JSX.Element {
  const dispatch = useDispatch()
  const location = useLocation()
  const patient = useSelector(getPatientSelector)
  const loadingPatient: boolean = useSelector(getLoading)
  const [patientChanges, setPatientChanges] = useState<Partial<Patient> | null>(null)

  const getCurrentPatientId = useCallback((): string => {
    const url = location.pathname.split('/')

    return url[url.indexOf('patients') + 1]
  }, [location.pathname])

  const handleInputChange = useCallback((e) => {
    const { name: inputName, value } = e.target
    let newState = {}

    if (inputName.indexOf('.') > -1) {
      const objectFromInput = Utils.dotSeparatedToObject(inputName, value)
      const objectFromInputKey = Object.keys(objectFromInput)[0]

      if (patient != null && Object.keys(patient).includes(objectFromInputKey)) {
        const objectToUpdate = Object.assign({}, patient[objectFromInputKey])
        const propertyToUpdate = Object.keys(objectFromInput[objectFromInputKey])[0]
        let partialNewState = {}

        objectToUpdate[propertyToUpdate] = value

        partialNewState = {
          [objectFromInputKey]: {
            ...patient[objectFromInputKey],
            ...objectToUpdate
          }
        }

        newState = {
          ...patient,
          ...partialNewState
        }
      } else {
        newState = {
          ...patient,
          ...Utils.dotSeparatedToObject(inputName, value)
        }
      }
    } else {
      newState = {
        ...patient,
        ...{ [inputName]: value }
      }
    }

    setPatientChanges(newState)
  }, [patient])

  const handlePatientUpdate = useCallback(async (e) => {
    e.preventDefault()

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await dispatch(updatePatientAction(getCurrentPatientId(), patientChanges))

      dispatch(pushNotification({
        // @ts-expect-error: TODO
        message: response?.message,
        title: Translations.translate('SUCCESS'),
        type: 'success'
      }))
    } catch (error) {
      console.error(error)

      dispatch(pushNotification({
        message: error.message,
        title: Translations.translate('ERROR'),
        type: 'error'
      }))
    }
  }, [dispatch, getCurrentPatientId, patientChanges])

  useEffect(() => {
    dispatch(getPatient(getCurrentPatientId()))
  }, [dispatch, getCurrentPatientId])

  useEffect(() => {
    return () => {
      dispatch(cleanPatient())
    }
  }, [dispatch])

  if (loadingPatient) {
    return <p>Loading...</p>
  }

  const title = `Editing patient: ${patient?.name ?? ''}`

  return (
    <BaseView title={title}>
      <PatientForm
        type='edit'
        patient={patientChanges ?? patient}
        handleInputChange={handleInputChange}
        handleSubmit={handlePatientUpdate}
      />
    </BaseView>
  )
}
