import StorageService from './storage'
import BaseHttpService from './baseHttpService'

class AuthenticationService {
  constructor () {
    this.apiV1 = BaseHttpService.getApiV1Url()
  }

  isAdmin () {
    return (this.isLoggedIn() && StorageService.read('user-data').role === 'admin')
  }

  isLoggedIn () {
    return Boolean(this.getToken())
  }

  getToken () {
    return StorageService.read('user-token')
  }

  getUserData () {
    return StorageService.read('user-data')
  }

  getUserLanguage () {
    return StorageService.read('user-language')
  }

  changePassword (userId, data) {
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify(data)
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/users/${userId}/change-password`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          if (!jsonResponse.success) {
            throw jsonResponse
          }

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  login (data) {
    const { email, password } = data
    const options = {
      ...BaseHttpService.getOptions(),
      method: 'post',
      body: JSON.stringify({ email, password })
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.apiV1}/authentication`, options)
        .then(response => response.json())
        .then(jsonResponse => {
          const { data, success } = jsonResponse

          if (!success) {
            throw jsonResponse
          }

          const { token, user } = data

          StorageService.persist('user-token', token)
          StorageService.persist('user-data', user)
          StorageService.persist('user-language', user.language)

          resolve(jsonResponse)
        })
        .catch(err => reject(err))
    })
  }

  logout () {
    StorageService.remove('user-token')
    StorageService.remove('user-data')
    StorageService.remove('user-language')
  }
}

export default new AuthenticationService()
