const initialState = {
  error: null,
  submitting: false,
  odonthogram: {
    data: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_OBSERVATION':
    case 'REQUEST_ODONTHOGRAM':
    case 'REQUEST_ODONTHOGRAM_UPDATE':
    case 'REMOVE_OBSERVATION_BEGIN': {
      return Object.assign({}, state, { submitting: true })
    }

    case 'ADD_OBSERVATION_FAILURE':
    case 'REQUEST_ODONTHOGRAM_FAILURE':
    case 'REQUEST_ODONTHOGRAM_UPDATE_FAILURE':
    case 'REMOVE_OBSERVATION_FAILURE': {
      return Object.assign({}, state, { error: action.payload, submitting: false })
    }

    case 'ADD_OBSERVATION_SUCCESS': {
      return Object.assign({}, state, { error: null, submitting: false })
    }

    case 'PAINT_FACE': {
      const { payload } = action
      const { face, piece } = payload
      const { color, key } = face
      const { odonthogram } = state

      if (!odonthogram.data[piece]) {
        const updatedOdonthogramData = Object.assign({}, odonthogram.data, { [piece]: { [key]: color } })

        state = Object.assign({}, state, { odonthogram: { data: updatedOdonthogramData } })
      } else {
        const updatedOdonthogramPiece = Object.assign({}, odonthogram.data[piece], { [key]: color })
        const updatedOdonthogram = Object.assign({}, odonthogram.data, { [piece]: updatedOdonthogramPiece })

        state = Object.assign({}, state, { odonthogram: { data: updatedOdonthogram } })
      }

      return Object.assign({}, state)
    }

    case 'APPLY_EXTRA': {
      const { payload } = action
      const { extra, piece } = payload
      const { color, key } = extra
      const { odonthogram } = state
      const odonthogramData = odonthogram.data

      if (!odonthogramData[piece]) {
        const updatedOdonthogram = Object.assign(
          {}, odonthogramData, { [piece]: { extrasApplied: { [key]: color } } }
        )

        state = Object.assign({}, state, { odonthogram: { data: updatedOdonthogram } })
      } else {
        let updatedExtrasApplied = {}

        if (odonthogramData[piece].extrasApplied) {
          if (odonthogramData[piece].extrasApplied[key] !== color) {
            updatedExtrasApplied = Object.assign({}, odonthogramData[piece].extrasApplied, { [key]: color })
          } else {
            const extrasAppliedWithDeletedExtra = odonthogramData[piece].extrasApplied
            delete extrasAppliedWithDeletedExtra[key]
            updatedExtrasApplied = Object.assign(
              {},
              odonthogramData[piece].extrasApplied,
              extrasAppliedWithDeletedExtra
            )
          }

          const updatedPiece = Object.assign({}, odonthogramData[piece], { extrasApplied: updatedExtrasApplied })
          const updatedOdonthogram = Object.assign({}, odonthogramData, { [piece]: updatedPiece })

          state = Object.assign({}, state, { odonthogram: { data: updatedOdonthogram } })
        } else {
          const updatedPiece = Object.assign({}, odonthogramData[piece], { extrasApplied: { [key]: color } })
          const updatedOdonthogram = Object.assign({}, odonthogramData, { [piece]: updatedPiece })

          state = Object.assign({}, state, { odonthogram: { data: updatedOdonthogram } })
        }
      }

      return Object.assign({}, state)
    }

    case 'SET_OBSERVATIONS': {
      const { observations = [] } = state

      return Object.assign({}, state, { observations: [...observations, action.payload] })
    }

    case 'REQUEST_ODONTHOGRAM_SUCCESS':
    case 'REQUEST_ODONTHOGRAM_UPDATE_SUCCESS': {
      return Object.assign({}, state, { odonthogram: action.payload, error: null, submitting: false })
    }

    case 'REMOVE_OBSERVATION_SUCCESS': {
      return {
        ...state,
        odonthogram: {
          data: {
            ...state.odonthogram.data,
            observations: action.payload.data.observations
          }
        }
      }
    }

    case 'CLEAN_ODONTHOGRAM_DATA':
      return {
        ...state,
        error: null,
        submitting: false,
        odonthogram: {
          data: {}
        }
      }

    default:
      return state
  }
}
