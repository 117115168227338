import { AuthenticationService, StorageService } from 'services'

class BaseHttpService {
  static getApiV1Url () {
    return (process.env.REACT_APP_DEV === 'true')
      ? 'http://localhost:3030/api/v1'
      : 'https://bepacient-api.nacq.online/api/v1'
  }

  static getApiV2Url () {
    return (process.env.REACT_APP_DEV === 'true')
      ? 'http://localhost:3030/api/v2'
      : 'https://bepacient-api.nacq.online/api/v2'
  }

  static getApiV3Url () {
    return (process.env.REACT_APP_DEV === 'true')
      ? 'http://localhost:3030/api/v3'
      : 'https://bepacient-api.nacq.online/api/v3'
  }

  static getOptions () {
    return {
      headers: {
        Authorization: `Bearer ${AuthenticationService.getToken()}`,
        'Content-Type': 'application/json',
        'X-Be-Pacient-Lang':
          StorageService.read('user-language')
            ? StorageService.read('user-language').toLowerCase()
            : 'en'
      }
    }
  }
}

export default BaseHttpService
