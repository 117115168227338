import React from 'react'
import { connect } from 'react-redux'
import { paintFace } from 'actions/pieces'
import { getSelectedTreatmentType } from 'selectors/treatmentType'

class Polygon extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      clicked: false,
      rightClicked: false
    }
  }

  handleClick (e) {
    e.preventDefault()

    const {
      extras,
      face,
      paintFace,
      pieceIndex,
      readOnly,
      selectedTreatmentType
    } = this.props

    if (!readOnly) {
      const faceData = {
        piece: pieceIndex,
        face: {
          color: 'none',
          key: face
        }
      }

      const clicked = !this.state.clicked

      if (clicked) {
        faceData.face.color = selectedTreatmentType
      }

      if (!extras.selectedExtra) {
        paintFace(faceData)
      }

      this.setState({ clicked })
    }
  }

  handleMouseEnter (e) {
    e.target.setAttribute('fill', '#d6ecfa')
  }

  handleMouseLeave (e) {
    e.target.setAttribute('fill', this.props.fill)
  }

  render () {
    const props = this.props

    return (
      <polygon
        id={props.id}
        data-face={props.face}
        points={props.points}
        fill={props.fill}
        stroke={props.stroke}
        strokeWidth={props.strokeWidth}
        opacity={props.opacity}
        onMouseEnter={this.handleMouseEnter.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onClick={this.handleClick.bind(this)}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({ paintFace: (faceData) => dispatch(paintFace(faceData)) })
const mapStateToProps = state => ({
  ...state,
  selectedTreatmentType: getSelectedTreatmentType(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Polygon)
