import BaseHttpService from './baseHttpService'

class Utils {
  dotSeparatedToObject (string, lastValue = {}) {
    return string.split('.').reduceRight((acc, currentValue) => {
      return { [currentValue]: acc }
    }, lastValue)
  }

  getApiVersion () {
    return new Promise(resolve => {
      fetch(`${BaseHttpService.getApiV1Url()}/health`)
        .then(response => response.json())
        .then(jsonResponse => {
          let version = '0.0.0'

          if (jsonResponse.success) {
            version = jsonResponse.version
          }

          resolve(version)
        })
    })
  }
}

export default new Utils()
