import React from 'react'
import { Translations } from 'services'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { uploadProfileImage } from 'actions/patients'
import { getPatientProfileImage } from 'selectors/patients'
import { formatDate } from 'utils/formatDate'

import './ProfileCard.css'

class ProfileCard extends React.Component {
  constructor () {
    super()

    this.fileInput = null
    this.state = {
      isProfileImageHovered: false,
      file: null
    }
  }

  getProfileImage () {
    return this.props.patientProfileImage || `${process.env.PUBLIC_URL}/assets/profile_default.png`
  }

  handleFileChange (event) {
    this.setState({ file: event.target.files[0] }, () => {
      this.uploadFile()
    })
  }

  uploadFile () {
    const { patients: { patient }, uploadFile } = this.props
    const { file } = this.state
    const data = new FormData()

    data.append('file', file)
    data.append('name', file.name)

    uploadFile(data, patient._id, 'profile')

    // reset input value after submitting
    this.fileInput.value = ''
  }

  handleProfileImageHover () {
    this.setState(prevState => ({
      isProfileImageHovered: !prevState.isProfileImageHovered
    }))
  }

  render () {
    const defaultPatient = { contact: {} }
    const { patient = defaultPatient } = this.props.patients
    const { isProfileImageHovered } = this.state

    return (
      <div className='ProfileCard'>
        <div className='ui card'>
          <div
            className={classnames('blurring dimmable image', {
              dimmed: isProfileImageHovered
            })}
            onMouseEnter={this.handleProfileImageHover.bind(this)}
            onMouseLeave={this.handleProfileImageHover.bind(this)}
          >
            <div className={classnames('ui dimmer transition', {
              hidden: !isProfileImageHovered,
              'visible active': isProfileImageHovered
            })}
            >
              <div className='content'>
                <div className='center'>
                  <div>
                    <label htmlFor='file' className='ui inverted button'>
                      {Translations.translate('PROFILE_CARD.UPDATE_PROFILE_PICTURE')}
                    </label>
                    <input
                      id='file'
                      type='file'
                      accept='image/*'
                      className='ProfileCard__file-input'
                      ref={ref => {
                        this.fileInput = ref
                      }}
                      onChange={this.handleFileChange.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <img alt={patient.name} src={this.getProfileImage()} />
          </div>
          <div className='content'>
            <p className='header'>{patient.name}</p>
            <div className='meta'>
              <span className='date'>
                {Translations.translate('PROFILE_CARD.CREATED_AT')} {formatDate(patient.createdAt)}
              </span>
            </div>
            <div className='description'>
              <span className='right floated'>{formatDate(patient.dateOfBirth)}</span>
              <span>{Translations.translate('PROFILE_CARD.DOB')}:</span>
            </div>
            <div className='description'>
              <span className='right floated'>
                {(patient.contact && patient.contact.phoneNumber) || '-'}
              </span>
              <span>
                <i className='phone icon' />
              </span>
            </div>
            <div className='description'>
              <span className='right floated'>
                {(patient.contact && patient.contact.cellphoneNumber) || '-'}
              </span>
              <span>
                <i className='mobile alternate icon' />
              </span>
            </div>
            <div className='description'>
              <span className='right floated'>
                {(patient.contact && patient.contact.email) || '-'}
              </span>
              <span>
                <i className='envelope outline icon' />
              </span>
            </div>
          </div>
          <div className='extra content'>
            <p>{Translations.translate('PROFILE_CARD.LAST_UPDATE')} {formatDate(patient.updatedAt)}</p>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  uploadFile: (fileData, patientId, type) => dispatch(uploadProfileImage(fileData, patientId))
})
const mapStateToProps = state => ({
  ...state,
  patientProfileImage: getPatientProfileImage(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)
