import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { hideContextMenu } from 'actions/contextMenu'
import { getContextMenuData, getIsContextMenuVisible } from 'selectors/contextMenu'
import './ContextMenu.css'

function ContextMenu () {
  const dispatch = useDispatch()
  const isContextMenuVisible = useSelector(getIsContextMenuVisible)
  const contextMenuData = useSelector(getContextMenuData)
  const wrapperRef = useRef(null)
  const handleClickOutside = useCallback((event) => {
    if (!wrapperRef?.current?.contains(event.target)) {
      dispatch(hideContextMenu())
      document.removeEventListener('click', handleClickOutside)
    }
  }, [dispatch])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    // NOTE: this component is mounted in App.js, this never happens
    // return () => {
    // document.removeEventListener('click', handleClickOutside)
    // }
  }, [handleClickOutside, isContextMenuVisible])

  if (!isContextMenuVisible) {
    return null
  }

  const { items, position } = contextMenuData
  const { x, y } = position

  const handleItemClick = ({ action }) => {
    dispatch(hideContextMenu())
    action()
  }

  return (
    <div className='ContextMenu' style={{ top: y, left: x }} ref={wrapperRef}>
      <div className='ui vertical menu'>
        {items.map((item, index) => (
          <a
            key={index}
            className={classNames({
              'teal item context-menu__item': true,
              disabled: !item.enabled
            })}
            onClick={() => handleItemClick(item)}
          >
            {item.label}
          </a>
        ))}
      </div>
    </div>
  )
}

export default ContextMenu
