import React, { useCallback } from 'react'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useHistory } from 'react-router-dom'
import * as z from 'zod'

import { createUserFailure, createUserRequest, createUserSuccess } from 'actions/users'
import { AuthScreenWrapper } from 'components/AuthScreenWrapper'
import { Button } from 'components/ui/button'
import { Version } from 'components/Version/Version'
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Translations, UsersService } from 'services'
import { getCreateUserResponse } from 'selectors/users'

const formSchema = z.object({
  email: z.string().email(),
  name: z.string(),
  password: z.string().min(8),
  repeatPassword: z.string().min(8)
})

export const Register: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const response = useSelector(getCreateUserResponse)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      name: '',
      password: '',
      repeatPassword: ''
    }
  })

  const onSubmit = useCallback(({ email, name, password, repeatPassword }: z.infer<typeof formSchema>) => {
    if ((email != null) && (name != null) && (password != null) && password === repeatPassword) {
      dispatch(createUserRequest({ email, name, password }))

      UsersService.create({ email, name, password }, true)
        .then(response => {
          dispatch(createUserSuccess(response))

          history.push('/login')

          ReactGA.event({
            category: 'Register',
            action: 'User registered account successfully',
            label: email
          })
        })
        .catch(err => {
          dispatch(createUserFailure(err))
        })
    }
  }, [dispatch, history])

  return (
    <AuthScreenWrapper>
      <Helmet title="Be Pacient | Register" />
      <div className='mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]'>
        <div className='text-center'>
          <div className='flex flex-row space-y-2 items-center justify-center'>
            <img src='/assets/be-pacient.png' className='w-12 mr-2' alt='Be Pacient' />
            <h2 className='text-2xl font-semibold tracking-tight'>
              {Translations.translate('REGISTER.CREATE_AN_ACCOUNT')}
            </h2>
          </div>
          <p className='text-sm text-muted-foreground mt-4'>
            {Translations.translate('REGISTER.COMPLETE_FIELDS')}
          </p>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder={Translations.translate('REGISTER_FORM.EMAIL_ADDRESS')}
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={Translations.translate('REGISTER_FORM.FULL_NAME')}
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder={Translations.translate('REGISTER_FORM.PASSWORD')}
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="repeatPassword"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder={Translations.translate('REGISTER_FORM.REPEAT_PASSWORD')}
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className='w-full
              inline-flex
              items-center
              justify-center
              whitespace-nowrap
              rounded-md
              text-sm
              font-medium
              transition-colors
              focus-visible:outline-none
              focus-visible:ring-1
              focus-visible:ring-ring
              disabled:pointer-events-none
              disabled:opacity-50
              bg-primary
              text-primary-foreground
              shadow
              hover:bg-primary/90
              h-10
              px-4
              py-2'
            >
              {Translations.translate('REGISTER_FORM.REGISTER')}
            </Button>
          </form>
        </Form>
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare */}
        {response?.success === false && (
          <div>
            <p className='text-red-400'>
              {Translations.translate('REGISTER.REGISTER_ERROR')}
            </p>
          </div>
        )}
        <div className='relative'>
          <div className='absolute inset-0 flex items-center'>
            <span className='w-full border-t' />
          </div>
          <div className='relative flex justify-center text-xs uppercase'>
            <span className='bg-background px-2 text-muted-foreground'>
              {Translations.translate('REGISTER.HAVE_ACCOUNT')}{' '}
            </span>
          </div>
        </div>
        <Button
          className='inline-flex
          items-center
          justify-center
          whitespace-nowrap
          rounded-md
          text-sm
          font-medium
          transition-colors
          focus-visible:outline-none
          focus-visible:ring-1
          focus-visible:ring-ring
          disabled:pointer-events-none
          disabled:opacity-50
          border
          border-input
          bg-background
          shadow-sm
          hover:bg-accent
          hover:text-accent-foreground
          h-10
          px-4
          py-2'
          variant='outline'
          asChild
        >
          <Link to='/login'>
            {Translations.translate('REGISTER.LOGIN')}
          </Link>
        </Button>
        <Version color='black' />
      </div>
    </AuthScreenWrapper>
  )
}
