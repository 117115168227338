import { Patient } from '../types/patient'

type State = {
  error: string | null | { success: boolean, message: string, err?: string}
  loading: boolean
  patient: Patient
  patientDetailsTab: string
  patientImages: string[]
  patientPage: string
  patients: Patient[]
  patientsRequest: {
    asc: number
    itemsPerPage: number
    page: number
    sort: string
  }
  patientsTablePage: number
  totalPatients: number
}

export const getHasUnauthorizedError = (state): boolean => {
  const { error } = state.patients

  return error != null &&
    error.success === false &&
    error.message === 'jwt expired' &&
    error.err === 'UnauthorizedError'
}

export const getError = (state): State['error'] => {
  return state.patients.error
}

export const getPatient = (state): State['patient'] | undefined => {
  return state.patients.patient
}

export const getPatients = (state): State['patients'] => {
  return state.patients?.patients ?? []
}

export const getLoading = (state): State['loading'] => {
  return state.patients.loading
}

export const getPatientProfileImage = (state): State['patient']['profileImage'] => {
  const profileImage = state.patients.patient.profileImage

  return profileImage?.image ?? null
}

export const getPatientImages = (state): State['patientImages'] => {
  return { ...state.patients.patientImages }
}

export const getPatientsRequest = (state): State['patientsRequest'] => {
  return state.patients.patientsRequest
}

export const getTotalPatients = (state): State['totalPatients'] => {
  return state.patients.totalPatients
}

export const getPatientsTablePage = (state): State['patientsTablePage'] => {
  return state.patients.patientsRequest.page
}
