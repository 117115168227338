import React from 'react'
import classNames from 'classnames'

export default class SE extends React.Component {
  render () {
    const { color, reference } = this.props

    return (
      <svg className={classNames({ reference })}>
        <text x='3' y='14' stroke={color} fontSize='11'>SE</text>
      </svg>
    )
  }
}
