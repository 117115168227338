import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import PieceRow from 'components/PieceRow/PieceRow'
import { useFeature } from 'hooks/useFeature'
import { usePatientPDF } from 'hooks/usePatientPDF'
import { Translations } from 'services'

import './Odontogram.css'

type Props = {
  date: string
  id: string
  patient: string
  readOnly?: boolean
  page?: string
}

export default function Odontogram ({
  date,
  id,
  page,
  patient,
  readOnly = false
}: Props): JSX.Element {
  const history = useHistory()
  const { isEnabled } = useFeature('patient-pdf-generation')
  const { generate: generatePDF } = usePatientPDF()

  const handleGeneratePDFClick = useCallback(() => {
    const odontogram = document.getElementById(
      `patient_${patient}_odontogram_${id}`
    )?.cloneNode(true) as Element

    generatePDF(odontogram)
  }, [patient, id, generatePDF])

  return (
    <div className='Odontogram'>
      {(isEnabled) && (
        <button onClick={handleGeneratePDFClick}>
          Generate pdf
        </button>
      )}
      <div className='Odontogram__wrapper'>
        <svg width='610' height='240' id={`patient_${patient}_odontogram_${id}`}>
          {/*
            NOTE: this is a dirty hack to move the entire odontogram 15px down because the top numbers were
            overflowing the svg element.
          */}
          <g transform='translate(0, 20)'>
            <PieceRow rowType='T' rowId='1' rowPosition='R' quadrant='2' readOnly={readOnly} page={page} />
            <PieceRow rowType='T' rowId='1' rowPosition='L' quadrant='1' readOnly={readOnly} page={page} />
            <PieceRow rowType='T' rowId='2' rowPosition='R' quadrant='3' readOnly={readOnly} page={page} />
            <PieceRow rowType='T' rowId='2' rowPosition='L' quadrant='4' readOnly={readOnly} page={page} />

            <PieceRow rowType='B' rowId='3' rowPosition='R' quadrant='6' readOnly={readOnly} page={page} />
            <PieceRow rowType='B' rowId='3' rowPosition='L' quadrant='5' readOnly={readOnly} page={page} />
            <PieceRow rowType='B' rowId='4' rowPosition='R' quadrant='7' readOnly={readOnly} page={page} />
            <PieceRow rowType='B' rowId='4' rowPosition='L' quadrant='8' readOnly={readOnly} page={page} />
          </g>
        </svg>
      </div>
      {page === 'patientDetail' && (
        <div className='Odontogram__footer'>
          <div className='Odontogram__edit-link'>
            <button className='ui blue basic button mini' onClick={() => {
              const route = `/patients/${patient}/odonthogram/${id}/edit`
              history.push(route)
            }}>
              {Translations.translate('ODONTOGRAM.EDIT')}
            </button>
          </div>
          <div className='Odontogram__date'>
            {(date.length > 0)
              ? <p>{Translations.translate('ODONTOGRAM.CREATED_AT')}: {date}</p>
              : <p>{Translations.translate('ODONTOGRAM.NO_DATA')}</p>}
          </div>
        </div>
      )}
    </div>
  )
}
