import React, { useCallback, useEffect } from 'react'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { getUserPreferences } from 'actions/users'
import { loginFailure, loginRequest, loginSuccess, logout } from 'actions/authentication'
import { Version } from 'components/Version/Version'
import { Button } from 'components/ui/button'
import { AuthScreenWrapper } from 'components/AuthScreenWrapper'
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { AuthenticationService, Translations } from 'services'
import { getLoginResponse } from 'selectors/authentication'

const formSchema = z.object({
  email: z.string(),
  password: z.string()
}).required({
  email: true,
  password: true
})

export const Login: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const response = useSelector(getLoginResponse)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onSubmit = useCallback(({ email, password }: z.infer<typeof formSchema>) => {
    if (email != null && password != null) {
      dispatch(loginRequest({ email, password }))

      AuthenticationService.login({ email, password })
        .then(response => {
          const user = response.data.user
          const { _id, email } = user

          dispatch(loginSuccess(response))

          dispatch(getUserPreferences())

          history.push(AuthenticationService.isAdmin() ? '/admin' : '/')

          ReactGA.event({
            category: 'Login',
            action: 'User logged in successfully',
            label: email
          })

          ReactGA.set({ userId: _id, email })
        })
        .catch(err => dispatch(loginFailure(err)))
    }
  }, [dispatch, history])

  useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <AuthScreenWrapper>
      <Helmet title="Be Pacient | Login" />
      <div className='mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]'>
        <div className='flex flex-row space-y-2 items-center justify-center'>
          <img src='/assets/be-pacient.png' className='w-12 mr-2' alt='Be Pacient' />
          <h2 className='text-2xl font-semibold tracking-tight'>
            {Translations.translate('LOGIN.LOGIN_INTO_ACCOUNT')}
          </h2>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder={Translations.translate('LOGIN_FORM.EMAIL_ADDRESS')}
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder={Translations.translate('LOGIN_FORM.PASSWORD')}
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className='w-full
              inline-flex
              items-center
              justify-center
              whitespace-nowrap
              rounded-md
              text-sm
              font-medium
              transition-colors
              focus-visible:outline-none
              focus-visible:ring-1
              focus-visible:ring-ring
              disabled:pointer-events-none
              disabled:opacity-50
              bg-primary
              text-primary-foreground
              shadow
              hover:bg-primary/90
              h-10
              px-4
              py-2'
            >
              {Translations.translate('LOGIN_FORM.LOGIN')}
            </Button>
          </form>
        </Form>
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare */}
        {response?.success === false && (
          <div>
            <p className='text-red-400'>
              {Translations.translate('LOGIN.ERROR')}
            </p>
          </div>
        )}
        <div className='relative'>
          <div className='absolute inset-0 flex items-center'>
            <span className='w-full border-t' />
          </div>
          <div className='relative flex justify-center text-xs uppercase'>
            <span className='bg-background px-2 text-muted-foreground'>
              {Translations.translate('LOGIN.ARE_YOU_NEW')}{' '}
            </span>
          </div>
        </div>
        <Button
          className='inline-flex
          items-center
          justify-center
          whitespace-nowrap
          rounded-md
          text-sm
          font-medium
          transition-colors
          focus-visible:outline-none
          focus-visible:ring-1
          focus-visible:ring-ring
          disabled:pointer-events-none
          disabled:opacity-50
          border
          border-input
          bg-background
          shadow-sm
          hover:bg-accent
          hover:text-accent-foreground
          h-10
          px-4
          py-2'
          variant='outline'
          asChild
        >
          <Link to='/register'>
            {Translations.translate('LOGIN.REGISTER')}
          </Link>
        </Button>
        <Version color='black' />
      </div>
    </AuthScreenWrapper>
  )
}
