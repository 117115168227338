// import { begin, end, pendingTask } from 'react-redux-spinner'
import { pushNotification } from 'actions/notifications'
import { attatchPatientImageSuccess, detachPatientImageSuccess } from 'actions/patients'
import { BaseHttpService, Translations } from 'services'

export const CLEAN_FILES = 'CLEAN_FILES'
export const DELETE_FILE_BEGIN = 'DELETE_FILE_BEGIN'
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_V1_SUCCESS = 'DELETE_FILE_V1_SUCCESS'
export const IMAGE_UPLOAD_BEGIN = 'IMAGE_UPLOAD_BEGIN'
export const IMAGE_UPLOAD_FAILURE = 'IMAGE_UPLOAD_FAILURE'
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS'
export const GET_FILE_BEGIN = 'GET_FILE_BEGIN'
export const GET_FILE_FAILURE = 'GET_FILE_FAILURE'
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS'
export const GET_FILES_BEGIN = 'GET_FILES_BEGIN'
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE'
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS'
export const GET_IMAGE_BEGIN = 'GET_IMAGE_BEGIN'
export const GET_IMAGE_FAILURE = 'GET_IMAGE_FAILURE'
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS'
export const SELECT_IMAGE = 'SELECT_IMAGE'
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'

const API_URL = `${BaseHttpService.getApiV3Url()}/files`

export const cleanFiles = () => dispatch => {
  dispatch({
    type: CLEAN_FILES
  })
}

export const deleteFileBegin = () => dispatch => {
  dispatch({
    type: DELETE_FILE_BEGIN
    // [pendingTask]: begin
  })
}

export const deleteFileFailure = (error) => dispatch => {
  dispatch({
    type: DELETE_FILE_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

export const deleteFileSuccess = (data, fileId) => dispatch => {
  dispatch({
    type: DELETE_FILE_SUCCESS,
    // [pendingTask]: end,
    payload: data,
    fileId
  })
}

const deleteFileV1Success = (fileId) => dispatch => {
  dispatch({
    type: DELETE_FILE_V1_SUCCESS,
    // [pendingTask]: end,
    fileId
  })
}

export const deleteFileV3 = (fileId, type = 'image') => dispatch => {
  dispatch(deleteFileBegin())

  const options = {
    ...BaseHttpService.getOptions(),
    method: 'delete'
  }

  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/${fileId}`, options)
      .then(response => response.json())
      .then(jsonResponse => {
        if (!jsonResponse.success) {
          throw jsonResponse
        }

        if (type === 'image') {
          dispatch(deleteFileV1Success(fileId))
          dispatch(detachPatientImageSuccess(fileId))
        }

        if (type === 'file') {
          dispatch(deleteFileSuccess(jsonResponse, fileId))
        }

        dispatch(pushNotification({
          type: 'success',
          title: Translations.translate('ERROR'),
          message: Translations.translate('FILES.DELETE.SUCCESS_MESSAGE')
        }))

        resolve(jsonResponse)
      })
      .catch(err => {
        dispatch(deleteFileFailure(err))
        dispatch(pushNotification({
          type: 'error',
          title: Translations.translate('ERROR'),
          message: Translations.translate('FILES.DELETE.FAILURE_MESSAGE')
        }))

        reject(err)
      })
  })
}

const getFileBegin = () => dispatch => {
  dispatch({
    type: GET_FILE_BEGIN
    // [pendingTask]: begin
  })
}

const getFileFailure = (error) => dispatch => {
  dispatch({
    type: GET_FILE_FAILURE,
    // [pendingTask]: end,
    payload: error
  })
}

const getFileSuccess = (file) => dispatch => {
  dispatch({
    type: GET_FILE_SUCCESS,
    // [pendingTask]: end,
    payload: file
  })
}

const getFilesBegin = () => dispatch => {
  dispatch({
    type: GET_FILES_BEGIN
    // [pendingTask]: begin
  })
}

const getFilesFailure = (error) => dispatch => {
  dispatch({
    type: GET_FILES_FAILURE,
    payload: error
    // [pendingTask]: end
  })
}

const getFilesSuccess = (files) => dispatch => {
  dispatch({
    type: GET_FILES_SUCCESS,
    payload: files
    // [pendingTask]: end
  })
}

export const getImageBegin = () => dispatch => {
  dispatch({
    type: GET_IMAGE_BEGIN
    // [pendingTask]: begin
  })
}

export const getImageFailure = (error) => dispatch => {
  dispatch({
    type: GET_IMAGE_FAILURE,
    payload: error
    // [pendingTask]: end
  })
}

export const getImageSuccess = (response) => dispatch => {
  dispatch({
    type: GET_IMAGE_SUCCESS,
    payload: response
    // [pendingTask]: end
  })
}

export const getImage = (imageId) => dispatch => {
  dispatch(getImageBegin())

  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/${imageId}`, BaseHttpService.getOptions())
      .then(response => response.blob())
      .then(jsonResponse => {
        dispatch(getImageSuccess({ _id: imageId, image: jsonResponse }))

        resolve(jsonResponse)
      })
      .catch(error => {
        dispatch(getImageFailure(error))

        reject(error)
      })
  })
}

export const selectImage = (imageId) => dispatch => {
  dispatch({
    type: SELECT_IMAGE,
    payload: imageId
  })
}

export const uploadBegin = () => dispatch => {
  dispatch({
    type: IMAGE_UPLOAD_BEGIN
    // [pendingTask]: begin
  })
}

export const uploadFailure = (error) => dispatch => {
  dispatch({
    type: IMAGE_UPLOAD_FAILURE,
    payload: error
    // [pendingTask]: end
  })
}

export const uploadSuccess = (response) => dispatch => {
  dispatch({
    type: IMAGE_UPLOAD_SUCCESS,
    payload: response
    // [pendingTask]: end
  })
}

const uploadV2Failure = (error) => dispatch => {
  dispatch({
    type: UPLOAD_FAILURE,
    payload: error
    // [pendingTask]: end
  })
}

const uploadV2Success = (response) => dispatch => {
  dispatch({
    type: UPLOAD_SUCCESS,
    payload: response
    // [pendingTask]: end
  })
}

export const getFile = (fileId) => dispatch => {
  dispatch(getFileBegin())

  fetch(`${API_URL}/${fileId}`, BaseHttpService.getOptions())
    .then(response => response.blob())
    .then(file => {
      const fileUrl = URL.createObjectURL(file)

      window.open(fileUrl)

      dispatch(getFileSuccess(file))
    })
    .catch(error => {
      console.log('>>> error getting file', error)
      dispatch(getFileFailure(error))
    })
}

export const uploadFile = (fileData, patientId, type = 'image') => dispatch => {
  let url = `${API_URL}/upload?type=${type}`

  if (patientId) {
    url += `&patient=${patientId}`
  }

  const options = {
    ...BaseHttpService.getOptions(),
    body: fileData,
    // headers: {
    // This generates the error Multipart: Boundary not found on the server
    // 'Content-Type': 'multipart/form-data'
    // },
    method: 'post'
  }

  // content-type is making the api not handle the file properly
  delete options.headers['Content-Type']

  dispatch(uploadBegin())

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => response.json())
      .then(jsonResponse => {
        if (!jsonResponse.success) {
          throw jsonResponse
        }

        if (type === 'image') {
          dispatch(uploadSuccess(jsonResponse))
          dispatch(attatchPatientImageSuccess(jsonResponse.data))
        }

        if (type === 'file') {
          dispatch(uploadV2Success(jsonResponse))
        }

        dispatch(pushNotification({
          type: 'success',
          title: Translations.translate('ERROR'),
          message: Translations.translate('UPLOAD.SUCCESS_MESSAGE')
        }))

        resolve(jsonResponse)
      })
      .catch(err => {
        if (type === 'image') {
          dispatch(uploadFailure(err))
        }

        if (type === 'file') {
          dispatch(uploadV2Failure(err))
        }

        dispatch(pushNotification({
          type: 'error',
          title: Translations.translate('ERROR'),
          message: Translations.translate('UPLOAD.FAILURE_MESSAGE')
        }))

        reject(err)
      })
  })
}

export const getPatientFilesV3 = (patientId) => dispatch => {
  dispatch(getFilesBegin())

  return new Promise((resolve, reject) => {
    fetch(`${API_URL}/patient/${patientId}?type=file`, BaseHttpService.getOptions())
      .then(response => response.json())
      .then(filesResponse => {
        if (!filesResponse.success) {
          throw filesResponse
        }

        dispatch(getFilesSuccess(filesResponse.data))

        resolve(filesResponse)
      })
      .catch(err => {
        dispatch(getFilesFailure(err.message))

        dispatch(pushNotification({
          type: 'error',
          title: Translations.translate('ERROR'),
          message: Translations.translate('FILES.GET_FILES_ERROR')
        }))

        reject(err)
      })
  })
}
