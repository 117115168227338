import React, { useEffect, useState } from 'react'
import Utils from 'services/utils'
import packageJson from 'package.alias.json'

type Props = {
  color?: string
}

export function Version ({ color }: Props): JSX.Element {
  const [apiVersion, setApiVersion] = useState('0.0.0')

  useEffect(() => {
    Utils.getApiVersion()
      .then((version: string) => setApiVersion(version))
      .catch((error) => console.error(error))
  }, [])

  return (
    <p className='px-8 text-center text-sm text-muted-foreground'>
      v{packageJson.version} ({apiVersion})
    </p>
  )
}
