import { useEffect, useState } from 'react'

import BaseHttpService from 'services/baseHttpService'

export function useFeature (featureKey: string): { isEnabled: boolean } {
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    ;void (async () => {
      try {
        const apiUrl = BaseHttpService.getApiV1Url()
        const res = await fetch(`${apiUrl}/features/${featureKey}`, BaseHttpService.getOptions())

        if (!res.ok) {
          const err = await res.json()

          throw new Error(err.message ?? 'An error ocurred')
        }

        const { data, error, success } = await res.json()

        if (success !== true) {
          throw new Error(error ?? 'An error ocurred')
        }

        setIsEnabled(data.isEnabled)
      } catch (error) {
        console.error(error)

        setIsEnabled(false)
      }
    })()
  }, [featureKey])

  return {
    isEnabled
  }
}
