type SuccessResponse = {
  message: string
  success: true
  data: {
    token: string
    user: {
      _id: string
      createdAt: string
      email: string
      language: 'ES' | 'EN'
      name: string
      role: 'admin' | 'user'
    }
  }
}

type ErrorResponse = {
  message: string
  success: false
  error: string
}

type State = {
  submitting: boolean
  loginResponse: SuccessResponse | ErrorResponse
}

export function getSubmitting (state): State['submitting'] {
  return state.authentication.submitting
}

export function getLoginResponse (state): State['loginResponse'] {
  return state.authentication.loginResponse
}
