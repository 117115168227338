import { combineReducers } from 'redux'
// import { pendingTasksReducer } from 'react-redux-spinner'
import authentication from './authentication'
import contextMenu from './contextMenu'
import extras from './extras'
import files from './files'
import loading from './loading'
import modal from './modal'
import patients from './patients'
import pieces from './pieces'
import treatmentType from './treatmentType'
import users from './users'
import { reducer as notificationsReducer } from 'actions/notifications'

export default combineReducers({
  // add reducers here to combine it into one
  authentication,
  contextMenu,
  extras,
  files,
  loading,
  modal,
  notifications: notificationsReducer,
  patients,
  // pendingTasks: pendingTasksReducer,
  pieces,
  treatmentType,
  users
})
