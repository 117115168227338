import { getUserOdontogramColors } from 'selectors/users'

export const getSelectedTreatmentType = state => {
  return state.treatmentType.selectedType || 'none'
}

export const getSelectedTreatmentColorByType = (state, type) => {
  const userOdontogramColors = getUserOdontogramColors(state)
  if (type === 'none') {
    return 'transparent'
  }
  return userOdontogramColors[type]
}
